import { React, useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { Pencil, CheckLg, XLg } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx"; // Importing xlsx library
import { FaSort } from "react-icons/fa";

import Loader from "../components/Loader";
import Message from "../components/Message";
import VerticallyCenteredModal from "../components/SubmitModal";
import FieldWarning from "../components/FieldWarning";
import { setCredentials } from "../slices/authSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

import {
  useCreateVisitorMutation,
  useGetVisitorsQuery,
  useUpdateVisitorMutation,
} from "../slices/visitorsSlice";
import { toast } from "react-toastify";
import {
  getCurrentDate,
  getCurrentTime,
  getDateOfLast,
} from "../utils/getCurrentDate";

const VisitorsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const [editEnabled, setEditEnabled] = useState("");
  const [visitorName, setVisitorName] = useState("");
  const [visitorMobile, setVisitorMobile] = useState("");
  const [visitedOn, setVisitedOn] = useState(getCurrentDate());
  const [visitedStatus, setVisitedStatus] = useState("");
  const [notes, setNotes] = useState("");

  const [visitorNamePresent, setVisitorNamePresent] = useState("");
  const [visitorMobilePresent, setVisitorMobilePresent] = useState("");
  const [visitedOnPresent, setVisitedOnPresent] = useState();
  const [visitedStatusPresent, setVisitedStatusPresent] = useState("");
  const [notesPresent, setNotesPresent] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [filterValue, setFilterValue] = useState("");

  const [startDate, setStartDate] = useState(getDateOfLast(30));

  const [endDate, setEndDate] = useState(getCurrentDate());

  const [getApiQuery, setGetApiQuery] = useState(
    `visitedDate[gte]=${startDate}T00:00:00.000Z&visitedDate[lte]=${endDate}${filterValue}`
  );
  const [newUserAdded, setNewUserAdded] = useState("");
  const [todaysVisitorsCount, setTodaysVisitorsCount] = useState("");
  const [sortedVisitors, setSortedVisitors] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const dispatch = useDispatch();

  const filterOptions = [
    "&status=All",
    "&status=Visited",
    "&status=Contacted",
    "&status=Joined",
    "&status=Not Joining",
  ];

  let {
    data: visitors,
    isLoading,
    error,
    refetch,
  } = useGetVisitorsQuery({
    token: userInfo.token,
    query: getApiQuery,
  });

  setInterval(() => {
    setNewUserAdded(Date.now());
  }, 600000);

  const [updateVisitor] = useUpdateVisitorMutation();
  const [createVisitor] = useCreateVisitorMutation();

  useEffect(() => {
    if (visitorName?.length > 0) {
      setVisitorNamePresent(false);
    }

    if (visitorMobile?.length === 10 && Number(visitorMobile) > 0) {
      setVisitorMobilePresent(false);
    }

    if (visitedOn <= getCurrentDate()) {
      setVisitedOnPresent(false);
    }

    if (visitedStatus !== undefined || visitedStatus?.length > 0) {
      setVisitedStatusPresent(false);
    }

    if (notes?.length < 1000) {
      setNotesPresent(false);
    }
  }, [visitorName, visitorMobile, visitedOn, visitedStatus, notes]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (visitorName?.length < 1 || visitorName?.length > 100) {
      setVisitorNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (visitorMobile?.length !== 10) {
      setVisitorMobilePresent(true);
      fieldValidationSuccess = false;
    }

    if (visitedOn?.split("T")[0] > getCurrentDate()) {
      setVisitedOnPresent(true);
      fieldValidationSuccess = false;
    }

    if (notes?.length > 1000) {
      setNotesPresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid Visitor details"
      );
    return fieldValidationSuccess;
  };

  const handleAddVisitor = async (user) => {
    if (await verifyInputFeilds())
      try {
        const resData = await createVisitor({
          token: userInfo.token,
          payload: {
            gymId: userInfo.gymId._id,
            name: visitorName,
            mobileNumber: visitorMobile,
            visitedDate: visitedOn,
            notes,
          },
        }).unwrap();
        setNewUserAdded(resData);
        setVisitorName("");
        setVisitedOn(getCurrentDate());
        setEditEnabled("");
        setVisitedStatus("");
        setVisitorMobile("");
        setNotes("");
        toast.success("Visitor added");
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
  };

  const submitEdit = async () => {
    try {
      await updateVisitor({
        _id: editEnabled,
        token: userInfo.token,
        payload: {
          name: visitorName,
          mobileNumber: visitorMobile,
          visitedDate: visitedOn,
          status: visitedStatus,
          notes,
        },
      }).unwrap();
      setVisitorName("");
      setEditEnabled("");
      setVisitedOn(getCurrentDate());
      setVisitedStatus("");
      setVisitorMobile("");
      setNotes("");
      toast.success("Visitor updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const hanbdleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  const handleGetVisitors = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      if (endDate?.split("T")[0] > getCurrentDate())
        setEndDate(getCurrentDate() + getCurrentTime());
      // setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `visitedDate[gte]=${startDate}T00:00:00.000Z&visitedDate[lte]=${endDate}${filterValue}`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          visitorsStartDate: startDate,
          visitorsEndDate: endDate,
        })
      );
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch, editEnabled, newUserAdded]);

  useEffect(() => {
    const allVisitors = isLoading ? [] : error ? [] : visitors ? visitors : [];
    setTodaysVisitorsCount(allVisitors?.length);

    const sortedVisitors = [...allVisitors]?.sort((a, b) => {
      if (
        a[sortConfig.key]?.toString().toLowerCase() <
        b[sortConfig.key]?.toString().toLowerCase()
      ) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (
        a[sortConfig.key]?.toString().toLowerCase() >
        b[sortConfig.key]?.toString().toLowerCase()
      ) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setSortedVisitors(sortedVisitors);
  }, [setTodaysVisitorsCount, visitors, isLoading, error, sortConfig]);

  const handleSelect = (e) => {
    setVisitedStatus(e.target.value);
  };

  const handleStatusFilter = (val) => {
    if (val.includes("All")) setFilterValue("");
    else setFilterValue(val);
  };

  const hanbdleCancelEdit = () => {
    setEditEnabled(false);
    setVisitorName("");
    setVisitedOn(getCurrentDate());
    setVisitedStatus("");
    setVisitorMobile("");
    setNotes("");
  };

  const exportToExcel = () => {
    if (error) {
      toast.error(
        "No Visitors available to Download for the selected Date range"
      );
    } else {
      const formattedData = visitors?.map((visitor) => ({
        "Visitor Name": visitor.name,
        "Mobile Number": visitor.mobileNumber,
        "Visited Date": visitor.visitedDate
          ? visitor.visitedDate.split("T")[0]
          : visitor.visitedDate.split("T")[0],
        Status: visitor.status,
        Notes: visitor.notes,
        "Added By": visitor.addedBy,
        "Updated By": visitor.updatedBy,
      }));

      const workSheet = XLSX.utils.json_to_sheet(formattedData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "Visitors List");
      XLSX.writeFile(
        workBook,
        "Visitors from " + startDate + " to " + endDate + ".xlsx"
      );
    }
  };

  const handleSort = (columnKey) => {
    let direction = "asc";
    if (sortConfig?.key === columnKey && sortConfig?.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: columnKey, direction });
  };

  return (
    <>
      {userInfo.gymId.subscriptionType !== "Premium" ? (
        <>
          <label>
            {" "}
            <strong>
              {" "}
              {preferredLanguage[selectedLanguage]?.visitorPage.visitorsList} :
              &nbsp;
            </strong>
          </label>
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            onClick={(e) => setShowAdd(!showAdd)}
            variant="primary"
          >
            {showAdd
              ? preferredLanguage[selectedLanguage]?.visitorPage.showVisitors
              : preferredLanguage[selectedLanguage]?.visitorPage.addVisitor}
          </Button>
          <br />
          <br />
          <VerticallyCenteredModal
            title="Are you sure want to submit this ?"
            show={modalShow}
            size="sm"
            onHide={() => setModalShow(false)}
            onSubmit={submitEdit}
            setEdit={setEditEnabled}
          />
          {!showAdd ? (
            <>
              {" "}
              <Row
                className="mb-2"
                style={{
                  margin: "auto",

                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col xs={4} md="auto" sm={6}>
                  <Form.Group controlId="visitorMobile">
                    <Form.Control
                      type="date"
                      placeholder="2023-11-23"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={4} md="auto" sm={6}>
                  <Form.Group controlId="visitorMobile">
                    <Form.Control
                      type="date"
                      placeholder="2023-11-27"
                      value={endDate?.split("T")[0]}
                      onChange={(e) => setEndDate(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={4} md="auto" sm={6}>
                  <Form.Group controlId="filterBy">
                    <Form.Select
                      id="filterBy"
                      value={filterValue}
                      onChange={(e) => handleStatusFilter(e.target.value)}
                    >
                      {filterOptions.map((e) => (
                        <option key={e} value={e}>
                          {e?.split("=")[1]}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col
                  xs={6}
                  md="auto"
                  sm={6}
                  className="d-flex justify-content-center"
                >
                  <Form.Group className="my-1" controlId="gymType">
                    <Button
                      id="filterBy"
                      value={filterValue}
                      onClick={handleGetVisitors}
                      className={`${userInfo.themeColor}ThemeHeaderTop`}
                    >
                      Get Visitors
                    </Button>
                  </Form.Group>
                </Col>
                <Col xs={12} md="auto" sm={6}>
                  <Form.Group as={Row} controlId="visitorMobile">
                    <Button className="buttonOrchid">
                      {
                        preferredLanguage[selectedLanguage]?.visitorPage
                          .totalVisitors
                      }{" "}
                      <strong> {todaysVisitorsCount}</strong>
                    </Button>
                  </Form.Group>
                </Col>
                <Col
                  xs={6}
                  md="auto"
                  sm={6}
                  className="d-flex justify-content-center"
                >
                  {!userInfo?.isMobileScreen && (
                    <Button onClick={exportToExcel} variant="success">
                      {
                        preferredLanguage[selectedLanguage]?.visitorPage
                          .downloadVisitors
                      }
                    </Button>
                  )}
                </Col>
              </Row>
              {isLoading ? (
                <Loader />
              ) : error ? (
                <Message>{error?.data?.error}</Message>
              ) : (
                <div style={{ height: "100vh" }}>
                  <div className="tableContainer">
                    <Table
                      striped
                      bordered
                      hover
                      responsive="sm"
                      className="table-custom"
                    >
                      <thead className={`${userInfo.themeColor}ThemeThead`}>
                        <tr>
                          <th
                            onClick={() => handleSort("name")}
                            style={{ cursor: "pointer" }}
                          >
                            Name
                            <FaSort />
                          </th>
                          <th
                            onClick={() => handleSort("mobileNumber")}
                            style={{ cursor: "pointer" }}
                          >
                            Mobile <FaSort />
                          </th>
                          <th
                            onClick={() => handleSort("visitedDate")}
                            style={{ cursor: "pointer" }}
                          >
                            Date <FaSort />
                          </th>
                          <th
                            onClick={() => handleSort("status")}
                            style={{ cursor: "pointer" }}
                          >
                            Status <FaSort />
                          </th>
                          <th>Added By</th>
                          <th>Updated By</th>
                          <th
                            onClick={() => handleSort("notes")}
                            style={{ cursor: "pointer" }}
                          >
                            Notes
                            <FaSort />
                          </th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                        {sortedVisitors ? (
                          sortedVisitors.map((user) => (
                            <>
                              {editEnabled === user._id ? (
                                <tr>
                                  <td>
                                    <Form.Control
                                      value={visitorName}
                                      type="text"
                                      onChange={(e) =>
                                        setVisitorName(e.target.value)
                                      }
                                    ></Form.Control>
                                    {visitorNamePresent ? (
                                      <FieldWarning text="Please Visitor name" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      pattern="[0-9]*" // Allow only digits
                                      value={visitorMobile}
                                      onChange={(e) => {
                                        const value = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        ); // Remove non-numeric characters
                                        setVisitorMobile(value);
                                      }}
                                    />
                                    {visitorMobilePresent ? (
                                      <FieldWarning text="Please provide 10 digit mobile number" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <Form.Control
                                      value={visitedOn?.split("T")[0]}
                                      type="date"
                                      onChange={(e) =>
                                        setVisitedOn(e.target.value)
                                      }
                                    ></Form.Control>

                                    {visitedOnPresent ? (
                                      <FieldWarning text="Please provide valid date in format DD/MM/YYYY" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    <Form.Select
                                      id="searchBy"
                                      value={
                                        visitedStatus
                                          ? visitedStatus
                                          : user.status
                                      }
                                      onChange={handleSelect}
                                      style={{ height: "100%" }}
                                    >
                                      <option value={user.status}>
                                        {user.status}
                                      </option>
                                      <option value="Contacted">
                                        Contacted
                                      </option>
                                      <option value="Joined">Joined</option>
                                      <option value="Not Joining">
                                        Not Joining
                                      </option>
                                    </Form.Select>
                                    {visitedStatusPresent ? (
                                      <FieldWarning text="Please select valid status" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>{user.addedBy}</td>
                                  <td>{user.updatedBy}</td>
                                  <td>
                                    <Form.Control
                                      value={notes}
                                      type="text"
                                      onChange={(e) => setNotes(e.target.value)}
                                    ></Form.Control>
                                    {notesPresent ? (
                                      <FieldWarning text="notes should not have more then 1000 chars" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td>
                                    {editEnabled ? (
                                      <>
                                        <button
                                          onClick={hanbdleSubmit}
                                          style={{
                                            borderRadius: "10%",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <CheckLg />
                                        </button>
                                        <button
                                          onClick={hanbdleCancelEdit}
                                          style={{
                                            borderRadius: "10%",
                                            margin: "8%",
                                          }}
                                        >
                                          <XLg />
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                <tr key={user._id}>
                                  <td>{user.name}</td>
                                  <td>{user.mobileNumber}</td>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {user.visitedDate
                                      ? user.visitedDate.split("T")[0]
                                      : ""}
                                  </td>
                                  <td>{user.status}</td>
                                  <td>{user.addedBy}</td>
                                  <td>{user.updatedBy}</td>
                                  <td>{user.notes}</td>
                                  <td>
                                    <button
                                      style={{ borderRadius: "20%" }}
                                      onClick={() => {
                                        setVisitorName(user.name);
                                        setEditEnabled(user._id);
                                        setNotes(user.notes);
                                        setVisitorMobile(user.mobileNumber);
                                        setVisitedOn(user.visitedDate);
                                        setVisitedStatus(user.status);
                                      }}
                                    >
                                      <Pencil />
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </>
                          ))
                        ) : (
                          <Message>No visitors Available</Message>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </>
          ) : (
            <Row
              className="mb-0"
              style={{
                margin: "auto",
                width: "95%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Form.Label column="true" sm="3">
                  {preferredLanguage[selectedLanguage]?.visitorPage.visitorName}{" "}
                  <label style={{ color: "red" }}>*</label> :
                </Form.Label>
                <Col xs={8} md={4} sm={8}>
                  <Form.Control
                    type="text"
                    value={visitorName}
                    onChange={(e) => setVisitorName(e.target.value)}
                    placeholder="Visitor Name*"
                  />

                  {visitorNamePresent ? (
                    <FieldWarning text="Please Visitor name" />
                  ) : (
                    ""
                  )}
                </Col>{" "}
              </Form.Group>
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Form.Label column="true" sm="3">
                  {
                    preferredLanguage[selectedLanguage]?.visitorPage
                      .visitorMobile
                  }{" "}
                  <label style={{ color: "red" }}>*</label> :
                </Form.Label>
                <Col xs={8} md={4} sm={8}>
                  <Form.Control
                    type="text"
                    pattern="[0-9]*" // Allow only digits
                    value={visitorMobile}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      setVisitorMobile(value);
                    }}
                    placeholder="Visitor's Mobile*"
                  />

                  {visitorMobilePresent ? (
                    <FieldWarning text="Please provide 10 digit mobile number" />
                  ) : (
                    ""
                  )}
                </Col>{" "}
              </Form.Group>
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Form.Label column="true" sm="3">
                  {preferredLanguage[selectedLanguage]?.visitorPage.visitedDate}{" "}
                  :
                </Form.Label>
                <Col xs={8} md={4} sm={8}>
                  <Form.Control
                    type="date"
                    value={visitedOn}
                    onChange={(e) => setVisitedOn(e.target.value)}
                    placeholder="Visited Date"
                  />

                  {visitedOnPresent ? (
                    <FieldWarning text="Please provide valid date in format DD/MM/YYYY" />
                  ) : (
                    ""
                  )}
                </Col>{" "}
              </Form.Group>
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Form.Label column="true" sm="3">
                  {
                    preferredLanguage[selectedLanguage]?.visitorPage
                      .noteToRemember
                  }{" "}
                  :
                </Form.Label>
                <Col xs={8} md={4} sm={8}>
                  <Form.Control
                    type="text"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Notes"
                  />

                  {notesPresent ? (
                    <FieldWarning text="notes should not have more then 1000 chars" />
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="my-2" controlId="firtsName">
                <Col
                  xs={6}
                  md={8}
                  sm={6}
                  className="d-flex justify-content-center"
                >
                  <Button
                    className={`${userInfo.themeColor}ThemeHeaderTop`}
                    onClick={handleAddVisitor}
                  >
                    {preferredLanguage[selectedLanguage]?.visitorPage.submit}
                  </Button>
                </Col>
              </Form.Group>
            </Row>
          )}
        </>
      ) : (
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          <label style={{ color: "red" }}>
            This feature is available only for Premium Plus.
          </label>
          <br></br>
          <br></br>
          <h5>
            Today's visitors are tomorrow's customers. Why lose your new
            Customer?{" "}
          </h5>
          <h6>
            Please upgrade to Premium Plus to keep track of all your Visitors
            and get a chance to turn your Visitors into Customers
          </h6>
        </div>
      )}
    </>
  );
};

export default VisitorsScreen;
