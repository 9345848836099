import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Table,
  Badge,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import * as XLSX from "xlsx"; // Importing xlsx library
import jsPDF from "jspdf"; // Importing jsPDF library
import "jspdf-autotable"; // Importing jsPDF Autotable plugin
import { FaSort } from "react-icons/fa";

import Loader from "../components/Loader";
import Message from "../components/Message";
import { useGetPaymentsQuery } from "../slices/paymentSlice";
import { getCurrentDate, getDateOfLast } from "../utils/getCurrentDate";
import {
  useDeleteRenewalDataMutation,
  useGetRenewalsQuery,
} from "../slices/renewalSlice";
import { useGetUsersQuery } from "../slices/usersSlice";
import { setCredentials } from "../slices/authSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

const SubscriptionsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const [startDate, setStartDate] = useState(getDateOfLast(30));

  const [endDate, setEndDate] = useState(getCurrentDate());
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });
  const [renewalsToList, setRenewalsToList] = useState([]);
  const [deletePressed, setDeletePressed] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();
  const [renewalDate, setRenewalDate] = useState();
  const [renewalAmount, setRenewalAmount] = useState();
  const [adminPwd, setAdminPwd] = useState();
  const [pwdVisible, setPwdVisible] = useState(false);

  // const [startDate, setStartDate] = useState(
  //   new Date("2024-01-13").toISOString().split("T")[0]
  // );

  // const [endDate, setEndDate] = useState(
  //   new Date("2024-01-13").toISOString().split("T")[0]
  // );

  const [renewalQuery, setRenewalQuery] = useState(
    `?gymId=${userInfo.gymId._id}&renewedDate[gte]=${startDate}T00:00:00.000Z&renewedDate[lte]=${endDate}T23:59:59.000Z`
  );
  const setPwdVisibility = () => {
    setPwdVisible(!pwdVisible);
  };

  const dispatch = useDispatch();

  const {
    data: renewals,
    isLoading: renewalsLoading,
    error: renewalError,
    refetch,
  } = useGetRenewalsQuery({
    query: renewalQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [renewedUsers, setRenewedUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);

  // let totalAmount = 0;
  // paymentsToList.map((pl) => {
  //   return (totalAmount = totalAmount + pl.paidAmount);
  // });

  const [deleteRenewal, { isLoading: deleteRenewalLoading }] =
    useDeleteRenewalDataMutation();

  const handleGetPayments = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      // if (endDate?.split("T")[0] > getCurrentDate())
      //   setEndDate(getCurrentDate() + getCurrentTime());
      // setEndDate(getCurrentDate() + getCurrentTime());

      setRenewalQuery(
        `?gymId=${userInfo.gymId._id}&renewedDate[gte]=${startDate}T00:00:00.000Z&renewedDate[lte]=${endDate}T23:59:59.000Z`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          paymentsStartDate: startDate,
          paymentsEndDate: endDate,
        })
      );
    }
  };

  useEffect(() => {
    refetch();
    const allSubs = renewalsLoading
      ? []
      : renewals
      ? renewals.map((pl) => {
          if (pl?.compositeId?.joinedDate < pl?.renewedDate) {
            return { ...pl, subsType: "Renewal" }; // Add `subsType: "Renewal"`
          } else {
            return { ...pl, subsType: "New User" }; // Add `subsType: "New"`
          }
        })
      : [];

    setAllSubscriptions(allSubs);
    setRenewalsToList(allSubs);
    const reneUsers = renewalsLoading
      ? []
      : renewals
      ? renewals
          .filter((pl) => pl?.compositeId?.joinedDate < pl?.renewedDate) // Filter first
          .map((pl) => ({ ...pl, subsType: "Renewal" })) // Add `subsType`
      : [];
    setRenewedUsers(reneUsers);
    const newUsrs = renewalsLoading
      ? []
      : renewals
      ? renewals
          .filter((pl) => pl?.compositeId?.joinedDate === pl?.renewedDate) // Filter first
          .map((pl) => ({ ...pl, subsType: "New User" })) // Add `subsType`
      : [];
    setNewUsers(newUsrs);
  }, [renewals]);

  useEffect(() => {
    if (renewalError?.data?.error) {
      setAllSubscriptions([]);
      setRenewedUsers([]);
      setNewUsers([]);
    }
  }, [renewalError]);

  const handleFilterSelecttion = async (val) => {
    setRenewalsToList(val);
  };

  const [selectedRenDate, setSelectedRenDate] = useState(null);
  const [selectedRenId, setSelectedRenId] = useState(null);
  const [selectedRenAmount, setSelectedRenAmount] = useState(null);

  const clickedDelete = async (dt, usrId, amnt) => {
    setSelectedRenDate(dt);
    setSelectedRenId(usrId);
    setSelectedRenAmount(amnt);
    setDeletePressed(true);
  };

  const handleDelete = async () => {
    try {
      const resData = await deleteRenewal({
        userId: selectedRenId,
        subscriptionAmount: selectedRenAmount,
        adminPwd,
        renewedDate: selectedRenDate,
        token: userInfo?.token,
      }).unwrap();
      // console.log("resData");
      // console.log(resData);
      if (resData) {
        toast.success(resData.message);
      }

      setRenewalsToList(
        renewalsToList.filter((rn) => {
          return (
            rn.userId + "" + rn.renewedDate !==
            selectedRenId + "" + selectedRenDate
          );
        })
      );
    } catch (e) {
      console.log(e);
      toast.error("Delete failed : " + e.data?.error);
    }
  };

  const confirmDelete = async () => {
    if (
      selectedRenId === deleteUserId &&
      selectedRenDate.split("T")[0] === renewalDate &&
      selectedRenAmount.toString() === renewalAmount.toString()
    ) {
      handleDelete();
      setDeletePressed(false);
      setDeleteUserId("");
      setRenewalDate("");
      setAdminPwd("");
      setRenewalAmount("");
    } else {
      toast.error("Please enter correct member details");
    }
  };

  const handleCancelDelete = async () => {
    setDeletePressed(false);
    setDeleteUserId("");
    setRenewalDate("");
    setAdminPwd("");
    setRenewalAmount("");
  };

  return (
    <>
      <label>
        <strong> {preferredLanguage[selectedLanguage]?.subscriptions}</strong>
      </label>
      <Modal
        show={deletePressed}
        onHide={(e) => setDeletePressed(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <strong>
              Renewals and payments related to that renewal will be deleted
            </strong>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label style={{ color: "black" }}>
              Enter Member ID :
            </Form.Label>
            <Form.Control
              onChange={(e) => setDeleteUserId(e.target.value)}
              value={deleteUserId}
            ></Form.Control>
            <br />
            <Form.Label style={{ color: "black" }}>
              Enter Renewal Amount :
            </Form.Label>
            <Form.Control
              onChange={(e) => setRenewalAmount(e.target.value)}
              value={renewalAmount}
            ></Form.Control>
            <br />
            <Form.Label style={{ color: "black" }}>
              Enter Renewed Date :
            </Form.Label>
            <Form.Control
              placeholder="YYYY-MM-DD"
              onChange={(e) => setRenewalDate(e.target.value)}
              value={renewalDate}
            ></Form.Control>
            <br />
            <Form.Label style={{ color: "black" }}>
              Enter Your Pasword :
            </Form.Label>

            <InputGroup>
              <Form.Control
                type={pwdVisible ? "text" : "password"}
                placeholder="enter password"
                onChange={(e) => setAdminPwd(e.target.value)}
                value={adminPwd}
              ></Form.Control>
              <Button className="cyanThemeHeaderTop" onClick={setPwdVisibility}>
                {pwdVisible ? <Eye /> : <EyeSlash />}
              </Button>
            </InputGroup>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            onClick={confirmDelete}
          >
            Delete
          </Button>

          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Row
        className="my-1"
        style={{
          width: "90%",
          margin: "auto",
          paddingBottom: "0.5%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group as={Row} className="my-1" controlId="visitorMobile">
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              onClick={handleGetPayments}
            >
              Get Subscriptions
            </Button>
          </Form.Group>
        </Col>
      </Row>
      <Row
        className="mb-1"
        style={{
          width: "95%",
          margin: "auto",
          paddingBottom: "1%",
          justifyContent: "center",
        }}
      >
        <Button
          className="marginPointEight sixteenWidth buttonGreen numbersButtonPayementsList"
          onClick={() => handleFilterSelecttion(allSubscriptions)}
        >
          {preferredLanguage[selectedLanguage]?.paymentsListPage.total}{" "}
          <strong>{allSubscriptions?.length}</strong>
          {/* <Badge>{allSubscriptions?.length}</Badge> */}
        </Button>
        <Button
          className="marginPointEight sixteenWidth buttonTeal numbersButtonPayementsList"
          onClick={() => handleFilterSelecttion(renewedUsers)}
        >
          {preferredLanguage[selectedLanguage]?.paymentsListPage.renewal}{" "}
          <strong>{renewedUsers?.length}</strong>
          {/* <Badge>{renewedUsers?.length}</Badge> */}
        </Button>
        <Button
          className=" marginPointEight sixteenWidth buttonOrchid numbersButtonPayementsList"
          onClick={() => handleFilterSelecttion(newUsers)}
        >
          {preferredLanguage[selectedLanguage]?.paymentsListPage.new}{" "}
          <strong>{newUsers?.length}</strong>
          {/* <Badge>{newUsers?.length}</Badge> */}
        </Button>
      </Row>
      {renewalsLoading ? (
        <Loader />
      ) : renewalError ? (
        <Message>{renewalError?.data?.error}</Message>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr>
                  <th>Name</th>
                  <th>Id</th>
                  <th>Type</th>
                  <th>Subs Amount</th>
                  <th>Package</th>
                  <th>Date</th>
                  <th>Done By</th>
                  {(userInfo.role === "owner" ||
                    userInfo.role === "Super Admin") && <th></th>}
                </tr>
              </thead>
              {renewalsToList ? (
                <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                  {renewalsToList?.map((user) => (
                    <tr key={user.userId + "" + user.renewedDate}>
                      <td>
                        <Link to={`/userPayments/${user.userId}`}>
                          {user.compositeId
                            ? user.compositeId.firstName
                            : user.firstName}
                        </Link>
                      </td>
                      <td> {user.userId} </td>
                      <td style={{ whiteSpace: "nowrap" }}>{user.subsType} </td>
                      <td> {user.subscriptionAmount} </td>
                      <td>
                        {" "}
                        {user.selectedPackage
                          ? user.selectedPackage
                          : user.subscriptionType}
                      </td>

                      <td style={{ whiteSpace: "nowrap" }}>
                        {user.renewedDate?.split("T")[0]}
                      </td>

                      <td> {user?.associateId} </td>
                      <td>
                        {(userInfo.role === "owner" ||
                          userInfo.role === "Super Admin") &&
                          user.subsType === "Renewal" && (
                            <Button
                              onClick={(e) =>
                                clickedDelete(
                                  user.renewedDate,
                                  user.userId,
                                  user.subscriptionAmount
                                )
                              }
                            >
                              {" "}
                              Delete
                            </Button>
                          )}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <Message>No Payments Available for the selected Period</Message>
              )}
            </Table>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionsScreen;
