import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { setCredentials } from "../slices/authSlice";
import { useRegisterMutation } from "../slices/gymSlice";
import RegistrationContainer from "../components/RegistrationContainer";
import { getCurrentDate } from "../utils/getCurrentDate";

import { useRegisterOwnerMutation } from "../slices/associatesSlice";
import FieldWarning from "../components/FieldWarning";

const RegisterScreen = () => {
  // const [regCode, setRegCode] = useState();

  const [gymId, setGymId] = useState();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gymName, setGymName] = useState("");
  const [trxId, setTrxId] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [gymType, setGymType] = useState();
  const [email, setEmail] = useState("");
  const [contactNumber, setConatctNumber] = useState("");
  const [secondaryCondactNumbers, setSecondaryContactNumbers] = useState([]);
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState();
  const [stateName, setStateName] = useState("Select State");
  const [subscriptionType, setSubscriptionType] = useState("Free");
  const [subsAmount, setSubsAmount] = useState();
  const [paidAmount, setPaidAmount] = useState();
  const pageDomain = window.location.origin;

  const [gymIdPresent, setGymIdPresent] = useState(false);
  const [passwordPresent, setPasswordPresent] = useState(false);
  const [confirmPasswordPresent, setConfirmPasswordPresent] = useState(false);
  const [gymNamePresent, setGymNamePresent] = useState(false);
  const [ownerNamePresent, setOwnerNamePresent] = useState(false);
  const [gymTypePresent, setGymTypePresent] = useState(false);
  const [emailPresent, setEmailPresent] = useState(false);
  const [contactNumberPresent, setConatctNumberPresent] = useState(false);
  const [secondaryCondactNumbersPresent, setSecondaryContactNumbersPresent] =
    useState(false);
  const [addressPresent, setAddressPresent] = useState(false);
  const [pincodePresent, setPincodePresent] = useState(false);
  const [subscriptionTypePresent, setSubscriptionTypePresent] = useState();
  const [stateNamePresent, setStateNamePresent] = useState();

  const [pwdVisible, setPwdVisible] = useState(false);
  const [confirmPwdVisible, setConfirmPwdVisible] = useState(false);

  const gymTypesAvailable = [
    "Select Gym Type",
    "Gym",
    "CrossFit Gym",
    "Yoga Studio",
    "Dance Studio",
    "Gymnastic Center",
    "Women-Only Gym",
    "Martial Arts",
  ];

  const availableStateNames = [
    "Select State",
    "Andhra Pradesh",
    "Karnataka",
    "Kerala",
    "Maharashtra",
    "Tamil Nadu",
    "Telangana",
  ];

  const subscriptionTypes = ["Free", "Premium", "Premium Plus"];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();
  const [registerAssociate] = useRegisterOwnerMutation();

  // const userInfo = useSelector((state) => state.auth);

  let { search } = useLocation();
  const ps = new URLSearchParams(search);
  const redirect = ps.get("redirect") || "/";

  //   useEffect(() => {
  //     if (userInfo) {
  //       navigate(redirect);
  //     }
  //   }, [userInfo, redirect, navigate]);

  useEffect(() => {
    if (gymId?.length === 4) {
      setGymIdPresent(false);
    }
    if (password?.trim().length >= 8) {
      setPasswordPresent(false);
    }
    if (password === confirmPassword) {
      setConfirmPasswordPresent(false);
    }

    if (gymName?.trim().length > 0) {
      setGymNamePresent(false);
    }

    if (ownerName?.trim().length > 0) {
      setOwnerNamePresent(false);
    }

    if (gymType) {
      setGymTypePresent(false);
    }

    if (stateName !== "Select State") {
      setStateNamePresent(false);
    }

    if (subscriptionType) {
      setSubscriptionTypePresent(false);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setEmailPresent(false);
    }

    if (contactNumber?.length === 10 && contactNumber > 1) {
      setConatctNumberPresent(false);
    }

    if (secondaryCondactNumbers?.length > 0) {
      setSecondaryContactNumbersPresent(false);
    }

    if (address?.length > 10) {
      setAddressPresent(false);
    }

    if (pincode?.length === 6) {
      setPincodePresent(false);
    }
  }, [
    gymId,
    password,
    confirmPassword,
    gymName,
    ownerName,
    gymType,
    email,
    contactNumber,
    secondaryCondactNumbers,
    address,
    pincode,
    subscriptionType,
    stateName,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;
    if (gymId?.length !== 4) {
      setGymIdPresent(true);
      fieldValidationSuccess = false;
    }
    if (password?.trim().length < 8 || password?.length > 50) {
      setPasswordPresent(true);
      fieldValidationSuccess = false;
    }
    if (password !== confirmPassword) {
      setConfirmPasswordPresent(true);
      fieldValidationSuccess = false;
    }

    if (gymName?.trim().length < 1 || gymName?.length > 100) {
      setGymNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (ownerName?.trim().length < 1 || ownerName?.length > 100) {
      setOwnerNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (!gymType) {
      setGymTypePresent(true);
      fieldValidationSuccess = false;
    }

    if (stateName === "Select State") {
      setStateNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (!subscriptionType) {
      setSubscriptionTypePresent(true);
      fieldValidationSuccess = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email) || email?.length > 300) {
      setEmailPresent(true);
      fieldValidationSuccess = false;
    }

    if (contactNumber?.length !== 10 || contactNumber < 1) {
      setConatctNumberPresent(true);
      fieldValidationSuccess = false;
    }

    if (secondaryCondactNumbers?.length > 50) {
      setSecondaryContactNumbersPresent(true);
      fieldValidationSuccess = false;
    }

    if (address?.length < 10 || address?.length > 1000) {
      setAddressPresent(true);
      fieldValidationSuccess = false;
    }

    if (pincode?.length !== 6) {
      setPincodePresent(true);
      fieldValidationSuccess = false;
    }
    if (!fieldValidationSuccess)
      toast.error(
        `Some of the fields have invalid inputs.
         Please provide valid details`
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    function wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    if (await verifyInputFeilds())
      try {
        await register({
          _id: gymId,
          password,
          confirmPassword,
          name: gymName,
          ownerName,
          dueAmount: Number(subsAmount),
          paidAmount: Number(paidAmount),
          transactionId: trxId,
          gymType,
          email,
          stateName,
          createdFor: pageDomain.split("//")[1],
          primaryContact: contactNumber,
          secondaryContacts: secondaryCondactNumbers,
          address,
          pincode,
          subscriptionType,
        }).unwrap();
        toast.success(
          "Gym is Successfully Registered, Please wait for 3 seconds"
        );

        await wait(3000);

        const resAssociate = await registerAssociate({
          associateId: gymId?.substring(0, 2),
          gymId,
          firstName: ownerName,
          password,
          confirmPassword,
          role: "owner",
          roleId: 1,
          gender: "Male",
          mobileNumber: contactNumber,
          joinedDate: new Date().toISOString().split("T")[0],
          dateOfBirth: new Date().toISOString().split("T")[0],
          pincode,
          themeColor: "cyan",
        }).unwrap();
        toast.success("Owner profile created");
        dispatch(
          setCredentials({
            ...resAssociate,
            lastVisitedPage: "/",
            loginDate: getCurrentDate(),
            audioOn: true,
            visitorsStartDate: getCurrentDate(),
            visitorsEndDate: getCurrentDate(),
            attendancesStartDate: getCurrentDate(),
            attendancesEndDate: getCurrentDate(),
            paymentsStartDate: getCurrentDate(),
            paymentsEndDate: getCurrentDate(),
            dashboardStartDate: getCurrentDate(),
            dashboardEndDate: getCurrentDate(),
            attendancesStartDateOfUser: getCurrentDate(),
            attendancesEndDateOfUser: getCurrentDate(),
          })
        );

        // navigate(redirect);
        navigate("/");
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
  };

  const setPwdVisibility = () => {
    setPwdVisible(!pwdVisible);
  };
  const setConfirmPwdVisibility = () => {
    setConfirmPwdVisible(!confirmPwdVisible);
  };

  return (
    <RegistrationContainer>
      <Form onSubmit={submitHandler}>
        <Form.Group as={Row} className="my-2" controlId="id">
          <Form.Label column="true" sm="3">
            Gym Id <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={gymId}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setGymId(value);
              }}
              placeholder="enter Gym ID"
            />
            {gymIdPresent ? (
              <FieldWarning text="Please provide valid Gym ID" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="password">
          <Form.Label column="true" sm="3">
            Password <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <div style={{ display: "flex" }}>
              <InputGroup>
                <Form.Control
                  type={pwdVisible ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="enter password"
                ></Form.Control>
                <Button
                  className="cyanThemeHeaderTop"
                  onClick={setPwdVisibility}
                >
                  {pwdVisible ? <Eye /> : <EyeSlash />}
                </Button>
              </InputGroup>
            </div>
            {passwordPresent ? (
              <FieldWarning text="Password should be atleast 8 chars long" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="confirmPassword">
          <Form.Label column="true" sm="3">
            Confirm Password <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <div style={{ display: "flex" }}>
              <InputGroup>
                <Form.Control
                  value={confirmPassword}
                  type={confirmPwdVisible ? "text" : "password"}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="confirm password"
                ></Form.Control>
                <Button
                  className="cyanThemeHeaderTop"
                  onClick={setConfirmPwdVisibility}
                >
                  {confirmPwdVisible ? <Eye /> : <EyeSlash />}
                </Button>
              </InputGroup>
            </div>
            {confirmPasswordPresent ? (
              <FieldWarning text="Password and Confirm Password are not matching" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="my-2" controlId="name">
          <Form.Label column="true" sm="3">
            Gym Name <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={gymName}
              onChange={(e) => setGymName(e.target.value)}
              placeholder="enter Gym Name"
            ></Form.Control>
            {gymNamePresent ? (
              <FieldWarning text="Gym Name should have minimum 1 char and max 100 char" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="ownerName">
          <Form.Label column="true" sm="3">
            Owner Name <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
              placeholder="enter owner name"
            ></Form.Control>
            {ownerNamePresent ? (
              <FieldWarning text="Owner Name should have minimum 1 char and max 100 char" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="gymType">
          <Form.Label column="true" sm="3">
            Gym Type <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Select
              value={gymType}
              onChange={(e) => setGymType(e.target.value)}
              placeholder="Select Gym Type"
            >
              {gymTypesAvailable.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>
            {gymTypePresent ? (
              <FieldWarning text="Please select Gym type" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="subscriptionType">
          <Form.Label column="true" sm="3">
            Subscription Type <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Select
              value={subscriptionType}
              onChange={(e) => setSubscriptionType(e.target.value)}
              placeholder="Subscription Type"
            >
              {subscriptionTypes.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>
            {subscriptionTypePresent ? (
              <FieldWarning text="Please select Subscription type" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="id">
          <Form.Label column="true" sm="3">
            Subscription Amount <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              disabled={subscriptionType === "Free"}
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={subsAmount}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setSubsAmount(value);
              }}
              placeholder="enter subscription amount"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="id">
          <Form.Label column="true" sm="3">
            Paid Amount <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              disabled={subscriptionType === "Free"}
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={paidAmount}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setPaidAmount(value);
              }}
              placeholder="enter Paid amount"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="name">
          <Form.Label column="true" sm="3">
            Transaction Id :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={trxId}
              onChange={(e) => setTrxId(e.target.value)}
              placeholder="enter Transaction Id"
            ></Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="email">
          <Form.Label column="true" sm="3">
            Email <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="enter gym or owner's email"
            ></Form.Control>
            {emailPresent ? (
              <FieldWarning text="Please provide valid e-mail" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="contactNumber">
          <Form.Label column="true" sm="3">
            Contact Number <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <InputGroup>
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={contactNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setConatctNumber(value);
                }}
                placeholder="enter primary contact number"
              />
              <Button
                className="cyanThemeHeaderTop"
                style={{ cursor: "default" }}
              >
                {contactNumber?.length}
              </Button>
            </InputGroup>
            {contactNumberPresent ? (
              <FieldWarning text="Please provide 10 digit Mobile Number" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          className="my-2"
          controlId="secondaryContactNumbers"
        >
          <Form.Label column="true" sm="3">
            Secondary Contact Numbers :
          </Form.Label>
          <Col sm="6">
            <InputGroup>
              <Form.Control
                type="text"
                value={secondaryCondactNumbers}
                onChange={(e) => setSecondaryContactNumbers(e.target.value)}
                placeholder="enter secondary Contact Numbers"
              ></Form.Control>{" "}
              <Button
                className="cyanThemeHeaderTop"
                style={{ cursor: "default" }}
              >
                {secondaryCondactNumbers?.length}
              </Button>
            </InputGroup>
            {secondaryCondactNumbersPresent ? (
              <FieldWarning text="Please provide 10 digit mobile number" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="address">
          <Form.Label column="true" sm="3">
            Address <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="enter Gym Address"
            ></Form.Control>
            {addressPresent ? (
              <FieldWarning text="Address should be atleast 10 chars long" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="pincode">
          <Form.Label column="true" sm="3">
            Pincode <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={pincode}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setPincode(value);
              }}
              placeholder="enter pincode"
            />
            {pincodePresent ? (
              <FieldWarning text="Please provide valid 6 digit Pincode" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="stateName">
          <Form.Label column="true" sm="3">
            State <label style={{ color: "red" }}>*</label> :
          </Form.Label>
          <Col sm="6">
            <Form.Select
              value={stateName}
              onChange={(e) => setStateName(e.target.value)}
              placeholder="Select State"
            >
              {availableStateNames.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </Form.Select>
            {stateNamePresent ? (
              <FieldWarning text="Please select State" />
            ) : (
              ""
            )}
          </Col>
        </Form.Group>
        <Row column="true" sm="6">
          <Col sm="5"></Col>
          <Col sm="5">
            <Button
              className="cyanThemeHeaderTop"
              type="submit"
              disabled={isLoading}
              variant="primary"
            >
              {" "}
              Register
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="py-3">
        <Col style={{ color: "white", marginLeft: "19%" }}>
          Already have account ?{" "}
          <Link
            style={{ color: "white" }}
            to={redirect ? `/login?redirect=${redirect}` : "/login"}
          >
            {" "}
            Login
          </Link>
        </Col>
      </Row>
    </RegistrationContainer>
  );
};

export default RegisterScreen;
