import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useGetMyGymQuery } from "../slices/gymSlice";
import { useUpdateUserMutation } from "../slices/usersSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import VerticallyCenteredModal from "../components/SubmitModal";
import FieldWarning from "../components/FieldWarning";
import preferredLanguage from "../assets/preferredLanguage.json";

const RenewalScreen = ({ user, renewalStatus }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const [userId, setUserId] = useState(user.userId);
  const [firstName, setFirstName] = useState(user.firstName);
  const [ptEnabled, setPtEnabled] = useState(user.ptEnabled);
  const [lastPaidDate, setLastPaidDate] = useState(getCurrentDate());
  const [subscriptionAmount, setSubscriptionAmount] = useState();
  const [lastPaidAmount, setLastPaidAmount] = useState();
  const [offerDetails, setOfferDetails] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [joinedDate, setJoinedDate] = useState(user.joinedDate);
  const [lastRenewedDate, setLastRenewedDate] = useState(user.renewedDate);
  const [expiredDate, setExpiredDate] = useState(user.subscriptionExpiryDate);
  const [subscriptionType, setSubscriptionType] = useState(
    user.subscriptionType
  );
  const [packageType, setPackageType] = useState("Select Package");
  const [extraDays, setExtraDays] = useState();

  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";

  let {
    data: myGymDetails,
    isLoading: myGymDetailsLoading,
    error,
    refetch,
  } = useGetMyGymQuery({
    token: userInfo.token,
  });

  const [renewalDate, setRenewalDate] = useState(getCurrentDate());
  const [txnId, setTxnId] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [expiryDatePresent, setExpiryDatePresent] = useState(false);
  const [renewedDatePresent, setRenewedDatePresent] = useState(false);
  const [renewedDate1Present, setRenewedDate1Present] = useState(false);
  const [lastPaidDatePresent, setLastPaidDatePresent] = useState(false);
  const [subscriptionAmountPresent, setSubscriptionAmountPresent] =
    useState(false);
  const [lastPaidAmountPresent, setLastPaidAmountPresent] = useState(false);
  const [paidAmountGreaterThanSubsAmount, setPaidAmountGreaterThanSubsAmount] =
    useState(false);
  const [subscriptionTypePresent, setSubscriptionTypePresent] = useState(false);
  const [txnIdPresent, setTxnIdPresent] = useState(false);

  const subscriptionList = [
    "Select Months",
    "1 Month",
    "2 Months",
    "3 Months",
    "4 Months",
    "5 Months",
    "6 Months",
    "7 Months",
    "8 Months",
    "9 Months",
    "10 Months",
    "11 Months",
    "12 Months",
    "13 Months",
    "14 Months",
    "15 Months",
    "16 Months",
    "17 Months",
    "18 Months",
    "24 Months",
  ];

  const [packageList, setPackageList] = useState();
  const [packageDetails, setPackageDetails] = useState();

  useEffect(() => {
    refetch();
    if (!myGymDetailsLoading && myGymDetails) {
      const customPackage = myGymDetails?.customPackage || {};
      // console.log("customPackage:", customPackage);
      setPackageDetails(customPackage);
      setPackageList(["Select Package", ...Object.keys(customPackage)]);
    }
  }, [myGymDetailsLoading, myGymDetails, refetch]);

  const navigate = useNavigate();
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  useEffect(() => {
    if (Number(lastPaidAmount) >= 0 && Number(lastPaidAmount) < 500001) {
      setLastPaidAmountPresent(false);
    }

    if (Number(lastPaidAmount) < Number(subscriptionAmount)) {
      setPaidAmountGreaterThanSubsAmount(false);
    }

    if (Number(subscriptionAmount) > 0 && Number(subscriptionAmount) < 500001) {
      setSubscriptionAmountPresent(false);
    }

    if (txnId?.length > 0) {
      setTxnIdPresent(false);
    }

    if (
      subscriptionType !== undefined &&
      subscriptionType !== "Select Months"
    ) {
      setSubscriptionTypePresent(false);
    }

    if (lastPaidDate <= getCurrentDate()) {
      setLastPaidDatePresent(false);
    }

    if (renewalDate > joinedDate) {
      setRenewedDatePresent(false);
    }

    if (renewalDate > lastRenewedDate.split("T")[0]) {
      setRenewedDate1Present(false);
    }

    if (expiryDate > getCurrentDate()) {
      setExpiryDatePresent(false);
    }
  }, [
    ptEnabled,
    lastPaidAmount,
    lastPaidDate,
    renewalDate,
    subscriptionAmount,
    txnId,
    subscriptionType,
  ]);

  useEffect(() => {
    if (renewalDate && subscriptionType && packageType === "Select Package") {
      const monthsLater = new Date(renewalDate);
      monthsLater.setMonth(
        monthsLater.getMonth() + parseInt(subscriptionType?.split(" ")[0])
      );
      monthsLater.setDate(monthsLater.getDate() - 1);
      setExpiryDate(monthsLater.toISOString().slice(0, 10));
    }
  }, [renewalDate, subscriptionType]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (Number(lastPaidAmount) > Number(subscriptionAmount)) {
      setPaidAmountGreaterThanSubsAmount(true);
      fieldValidationSuccess = false;
    }

    if (
      !lastPaidAmount ||
      Number(lastPaidAmount) < 0 ||
      Number(lastPaidAmount) > 500001
    ) {
      setLastPaidAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (
      !subscriptionAmount ||
      Number(subscriptionAmount) <= 0 ||
      Number(subscriptionAmount) > 500001
    ) {
      setSubscriptionAmountPresent(true);
      fieldValidationSuccess = false;
    }

    if (txnId?.length > 40) {
      setTxnIdPresent(true);
      fieldValidationSuccess = false;
    }
    if (subscriptionType === "Select Months" || !subscriptionType) {
      setSubscriptionTypePresent(true);
      fieldValidationSuccess = false;
    }

    if (lastPaidDate > getCurrentDate()) {
      setLastPaidDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (renewalDate <= joinedDate) {
      setRenewedDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (renewalDate <= lastRenewedDate.split("T")[0]) {
      setRenewedDate1Present(true);
      fieldValidationSuccess = false;
    }

    if (expiryDate <= getCurrentDate()) {
      setExpiryDatePresent(true);
      fieldValidationSuccess = false;
    }

    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  useEffect(() => {
    // console.log("inside use effect");
    if (packageType !== "Select Package") {
      // console.log("inside ese effect 1");
      // console.log(packageDetails[packageType]?.months);
      // console.log(packageDetails[packageType]?.offerPrice);
      // console.log(packageDetails[packageType]?.extraDays);
      if (Number(packageDetails[packageType]?.months === 1))
        setSubscriptionType(packageDetails[packageType]?.months + " Month");
      else setSubscriptionType(packageDetails[packageType]?.months + " Months");
      setSubscriptionAmount(packageDetails[packageType]?.offerPrice);
      setExtraDays(packageDetails[packageType]?.extraDays);
      const currentDate = new Date(renewalDate);
      // console.log("currentDate");
      // console.log(currentDate);
      let expDate = new Date(
        currentDate.setMonth(
          currentDate.getMonth() + packageDetails[packageType]?.months
        )
      );
      // console.log("expDate");
      // console.log(expDate);
      expDate.setDate(
        expDate.getDate() + packageDetails[packageType]?.extraDays - 1
      );
      // console.log("expDate");
      // console.log(expDate);
      setExpiryDate(new Date(expDate).toISOString().split("T")[0]);
    }
  }, [packageType, renewalDate]);

  const submitHandler = async () => {
    // e.preventDefault();
    // const currentDate = new Date(renewalDate);
    // const monthsLater = new Date(currentDate);
    // monthsLater.setMonth(
    //   currentDate.getMonth() + parseInt(subscriptionType?.split(" ")[0])
    // );
    // monthsLater.setDate(monthsLater.getDate() - 1);
    // const subscriptionExpiryDate = monthsLater.toISOString().slice(0, 10);
    try {
      await updateUser({
        userId,
        payLoad: {
          lastPaidDate,
          subscriptionAmount,
          paidAmount: lastPaidAmount,
          subscriptionType,
          ptEnabled,
          selectedPackage:
            packageType === "Select Package" ? subscriptionType : packageType,
          renewedDate: renewalDate,
          subscriptionExpiryDate: expiryDate,
          transactionId: txnId,
          notes: offerDetails,
        },
        token: userInfo.token,
      }).unwrap();
      navigate(`/users/${user.userId}`);
      renewalStatus();
      toast.success("Renewal is Successful");
    } catch (e) {
      toast.error(e?.data?.message || e.data);
    }
  };

  const handleCancelButton = () => {
    navigate(`/users/${user.userId}`);
    renewalStatus();
  };

  const hanbdleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  return (
    <>
      {" "}
      <h5> Renewal </h5>
      {user.pendingAmount > 0 ? (
        <p style={{ color: "red" }}>
          You can not do Renewal when there is pending Amount, Please clear
          pending amount.
        </p>
      ) : (
        ""
      )}
      {user.renewedDate?.split("T")[0] === getCurrentDate() ? (
        <p style={{ color: "red" }}>
          You can not do Renewal again on the same day. Please renew tomorrow.
        </p>
      ) : (
        ""
      )}
      <VerticallyCenteredModal
        title="Are you sure want to Renew this ?"
        size="lg"
        payload={{
          "Paid Date": lastPaidDate,
          "Subscription Amount": subscriptionAmount,
          "Paid Amount": lastPaidAmount,
          "Subscription Type": subscriptionType,
          "Personal Trainer Enabled": ptEnabled ? "Yes" : "No",
          "Renewed Date": renewalDate,
          "Transaction Id": txnId,
        }}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
      />
      <Row>
        <Col md={8}>
          <Form.Group as={Row} className="my-2" controlId="userId">
            <Form.Label column="true" sm="4">
              ID :
            </Form.Label>
            <Col sm="5">
              <Form.Label>{userId}</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="4">
              Name :
            </Form.Label>
            <Col sm="5">
              <Form.Label>{firstName + " " + user.lastName}</Form.Label>
            </Col>
          </Form.Group>
          {user.pendingAmount > 0 ? (
            <Form.Group
              as={Row}
              className="my-2"
              controlId="firtsName"
              style={{ color: "red" }}
            >
              <Form.Label column="true" sm="4">
                Pending Amount :
              </Form.Label>
              <Col sm="5">
                <Form.Label>{user.pendingAmount}</Form.Label>
              </Col>
            </Form.Group>
          ) : (
            ""
          )}
          <Form.Group as={Row} className="my-2" controlId="renewalDate">
            <Form.Label column="true" sm="4">
              Renewal Date :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={renewalDate}
                onChange={(e) => setRenewalDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {renewedDatePresent ? (
                <FieldWarning text="Renewal date can not be less than joined date " />
              ) : (
                ""
              )}
              {renewedDate1Present && (
                <FieldWarning text="You can not do reverse renewal, New Renewal date should be greater than Last Renewed date " />
              )}
              <Form.Label style={{ color: "yellow" }}>
                Last Expired Date : {expiredDate?.toString().split("T")[0]}
              </Form.Label>
            </Col>{" "}
          </Form.Group>
          {packageList?.length > 1 && (
            <Form.Group as={Row} className="my-2" controlId="subscriptionType">
              <Form.Label column="true" sm="4">
                {preferredLanguage[selectedLanguage]?.registrationPage.package}{" "}
              </Form.Label>
              <Col sm="5">
                <Form.Select
                  value={packageType}
                  onChange={(e) => setPackageType(e.target.value)}
                  placeholder="Select Package"
                >
                  {packageList?.map((e) => (
                    <option key={e} value={e}>
                      {e}
                    </option>
                  ))}
                </Form.Select>{" "}
                {subscriptionTypePresent ? (
                  <FieldWarning text="Please select subscription type" />
                ) : (
                  ""
                )}
              </Col>
            </Form.Group>
          )}
          {packageList?.length > 1 && (
            <Form.Group as={Row} className="my-2" controlId="subscriptionType">
              <Form.Label column="true" sm="4">
                {
                  preferredLanguage[selectedLanguage]?.registrationPage
                    .extraDays
                }{" "}
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  value={extraDays}
                  // onChange={(e) => setPackageType(e.target.value)}
                  disabled
                ></Form.Control>{" "}
              </Col>
            </Form.Group>
          )}
          <Form.Group as={Row} className="my-2" controlId="subscriptionType">
            <Form.Label column="true" sm="4">
              Months * :
            </Form.Label>
            <Col sm="5">
              <Form.Select
                disabled={packageType !== "Select Package"}
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
                placeholder="Select subscription Type"
              >
                {subscriptionList.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>
              {subscriptionTypePresent ? (
                <FieldWarning text="Please select subscription type" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="trxId">
            <Form.Label column="true" sm="4">
              {
                preferredLanguage[selectedLanguage]?.registrationPage
                  .offerDetails
              }
              :
            </Form.Label>{" "}
            <Col sm="5">
              <Form.Control
                type="text"
                value={offerDetails}
                onChange={(e) => setOfferDetails(e.target.value)}
                placeholder="buy 3 months, 1 months free"
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="expiryDate">
            <Form.Label column="true" sm="4">
              {preferredLanguage[selectedLanguage]?.registrationPage.expiryDate}{" "}
              :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {expiryDatePresent ? (
                <FieldWarning text="Expiry date should be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="subscriptionAmount">
            <Form.Label column="true" sm="4">
              Subscription Amount * :
            </Form.Label>{" "}
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={subscriptionAmount}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setSubscriptionAmount(value);
                }}
                placeholder="enter Subscription Amount"
              />
              {subscriptionAmountPresent ? (
                <FieldWarning text="Subscription amount should be between 1 and 500000 Rs" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>{" "}
          <Form.Group as={Row} className="my-2" controlId="lastPaidAmount">
            <Form.Label column="true" sm="4">
              Amount Paid:
            </Form.Label>{" "}
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={lastPaidAmount}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setLastPaidAmount(value);
                }}
                placeholder="enter Paid Amount"
              />
              {lastPaidAmountPresent ? (
                <FieldWarning text="Please provide paid amount" />
              ) : (
                ""
              )}
              {paidAmountGreaterThanSubsAmount ? (
                <FieldWarning text="Paid amount can not be greater than Subscription amount" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>{" "}
          <Form.Group as={Row} className="my-2" controlId="trxId">
            <Form.Label column="true" sm="4">
              Transaction ID:
            </Form.Label>{" "}
            <Col sm="5">
              <Form.Control
                type="text"
                value={txnId}
                onChange={(e) => setTxnId(e.target.value)}
                placeholder="enter transaction ID"
              ></Form.Control>
              {txnIdPresent ? (
                <FieldWarning text="Please provide valid transaction id " />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>{" "}
          <Form.Group as={Row} className="my-2" controlId="lastPaidDate">
            <Form.Label column="true" sm="4">
              Paid Date :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={lastPaidDate}
                onChange={(e) => setLastPaidDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>{" "}
              {lastPaidDatePresent ? (
                <FieldWarning text="Paid date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="ptEnabled">
            <Form.Label column="true" sm="4">
              Personal Trainer Availed ?{" "}
            </Form.Label>
            <Col sm="5">
              <div className="horizontal-radio-group">
                {[true, false].map((option) => (
                  <div key={option}>
                    <Form.Check
                      type="radio"
                      label={option === true ? "YES" : "NO"}
                      name="ptEnabled"
                      id={`ptEnabled-${option}`}
                      value={option}
                      checked={ptEnabled === option}
                      onChange={(e) => setPtEnabled(option)}
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Form.Group>
          <Row
            sm="4"
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "2rem 0rem",
            }}
          >
            <Button
              type="submit"
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              disabled={
                isLoading ||
                user.pendingAmount > 0 ||
                user.renewedDate?.split("T")[0] === getCurrentDate()
              }
              variant="primary"
              onClick={hanbdleSubmit}
              style={{ maxWidth: "7rem" }}
            >
              {" "}
              Submit
            </Button>{" "}
            <Button
              style={{ marginLeft: "5%", maxWidth: "7rem" }}
              type="button"
              disabled={isLoading}
              variant="secondary"
              onClick={handleCancelButton}
            >
              {" "}
              Cancel
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default RenewalScreen;
