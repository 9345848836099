import { apiSlice } from "./apiSlice";
import { RENEWAL_URL } from "../constants";

const renewalsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRenewals: builder.query({
      query: (body) => ({
        url: `${RENEWAL_URL}${body.query ? body.query : ""}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    deleteRenewalData: builder.mutation({
      query: (data) => ({
        url: `${RENEWAL_URL}/${data.userId}`,
        method: "DELETE",
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const { useGetRenewalsQuery, useDeleteRenewalDataMutation } =
  renewalsApiSlice;
