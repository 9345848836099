import { Button, Form, Col, Row } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";

import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useUpdateAttendanceMutation } from "../slices/attendanceSlice";
import { useNavigate, useParams } from "react-router-dom";
import ProfileImage from "../components/ProfileImage";
import welcomeMessage from "../assets/audios/messageTone.mp3";
import defaultProfilePic from "../assets/defaultProfilePic.png";
import { getCurrentDate } from "../utils/getCurrentDate";
import errorTone from "../assets/audios/error_tone.mp3";
import birthdayTone from "../assets/audios/happyBirthday.mp3";

const AttendanceScreenFromMobile = () => {
  const [userId, setUserId] = useState();
  let storedUserId = null;
  let urlUpdated = false;
  const { gymUid: gymIdInUrl } = useParams();
  let gymIdInUrlB = gymIdInUrl;
  const navigate = useNavigate();
  // const [gymUid, setEncodedUrl] = useState(gymIdInUrl);
  let encodeVal = Date.now().toString();
  const startVal = encodeVal?.substring(0, 5);
  const endVal = encodeVal?.substring(5);
  const redirectUrl = false;
  // alert(`${startVal}${endVal}`);
  const [altImage, setAltImage] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [userIdError, setUserIdError] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const submitHandlerCalled = useRef(false);

  const handleImageError = () => {
    if (setImageError) {
      setImageError(true);
    }
    setAltImage(true);
  };

  const cacheBuster = new Date().getTime();

  // useEffect(() => {
  //   // alert(`${startVal}${gymIdInUrl}${endVal}`);
  //   navigate(`/attendanceFromMobile/${startVal}${gymIdInUrl}${endVal}`);
  // }, [redirectUrl, navigate]);

  const [updateAttendance, { isLoading }] = useUpdateAttendanceMutation();

  useEffect(() => {
    navigate(`/attendanceFromMobile/${startVal}${gymIdInUrl}${endVal}`);
    storedUserId = localStorage.getItem("userId");
    gymIdInUrlB = `${startVal}${gymIdInUrl}${endVal}`;
    if (storedUserId !== null && !submitHandlerCalled.current) {
      console.log("calling submit handler");
      submitHandler();
      submitHandlerCalled.current = true; // Mark as called
    }
  }, [urlUpdated, redirectUrl, navigate]);

  // console.log("userExist");
  // console.log(userExist);

  const submitHandler = async (e) => {
    // e.preventDefault();
    // if (!userInfo) toast.error("Please login to use the application");
    if (userId?.toString().length < 1 || userId?.toString().length > 8)
      toast.error("Please enter valid user Id");
    else {
      try {
        if (userId !== undefined) {
          localStorage.setItem("userId", userId);
        }
        const res = await updateAttendance({
          payLoad: {
            userId:
              userId?.toString().length > 0
                ? userId?.toString().toLowerCase()
                : storedUserId,
          },
          params: gymIdInUrlB,
        }).unwrap();
        // console.log("res");
        // console.log(res);

        setUserExist(true);
        setUserDetails(res);
        setUserId("");
      } catch (err) {
        if (err?.data?.error?.includes("Please enter valid User Id")) {
          setUserIdError(true);
        }
        err?.status?.status === "PARSING_ERROR"
          ? toast.error(() => (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  fontSize: "1.8rem",
                  marginTop: "30px",
                }}
              >
                <audio src={errorTone} type="audio/mp3" autoPlay></audio>
                Please enter valid user Id
              </div>
            ))
          : toast.error(() => (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  fontSize: "1.8rem",
                  marginTop: "30px",
                }}
              >
                <audio src={errorTone} type="audio/mp3" autoPlay></audio>
                {err.data.error}
                {err?.data?.error?.includes("User is not authorized") && (
                  <>
                    <br />
                    <br />
                    Please Scan again
                  </>
                )}
                <br />
              </div>
            ));
      }
    }
  };

  const clearStorage = () => {
    localStorage.clear();
    toast.success(
      " Now Scan again and Enter Your Member Id to mark attendance"
    );
  };
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        fontSize: "1.8rem",
      }}
    >
      {userExist ? (
        <Row
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "auto",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            backgroundColor: `${
              new Date(userDetails?.expiresOn) < new Date(getCurrentDate())
                ? "red"
                : "inheir"
            }`,
          }}
        >
          <Col>
            <audio
              src={
                new Date(userDetails?.expiresOn) < new Date(getCurrentDate())
                  ? errorTone
                  : userDetails?.dateOfBirth?.toString().split("T")[0] !==
                      "2021-01-01" &&
                    userDetails?.dateOfBirth
                      ?.toString()
                      .split("T")[0]
                      .substring(4) === getCurrentDate().substring(4)
                  ? birthdayTone
                  : welcomeMessage
              }
              type="audio/mp3"
              autoPlay
            ></audio>
            {altImage ? (
              <img
                src={defaultProfilePic}
                width={250}
                height={250}
                alt="Profile"
                style={{ borderRadius: "10%" }}
              />
            ) : (
              <img
                src={`https://zpottergym.s3.ap-south-1.amazonaws.com/${gymIdInUrl?.substring(
                  5,
                  9
                )}/${userDetails.userId}?${cacheBuster}`}
                width={250}
                height={250}
                onError={handleImageError} // Handle image loading error
                alt="Profile"
                style={{ borderRadius: "10%" }}
              />
            )}
            <br />
            <br />
            {new Date(userDetails?.expiresOn) < new Date(getCurrentDate()) ? (
              <>
                {userDetails?.userName},
                <br />
                Your Subscription Already Expired On{" "}
                {userDetails?.expiresOn?.split("T")[0]}
              </>
            ) : (
              <>
                <strong>
                  {userDetails?.message} {userDetails?.userName}
                </strong>
                {userDetails?.dateOfBirth?.toString().split("T")[0] !==
                  "2021-01-01" &&
                  userDetails?.dateOfBirth
                    ?.toString()
                    .split("T")[0]
                    .substring(4) === getCurrentDate().substring(4) && (
                    <>
                      <br />{" "}
                      <strong style={{ fontSize: "1.5em", color: "green" }}>
                        Happy Birthday
                      </strong>
                    </>
                  )}{" "}
                <strong>
                  <p style={{ color: "green" }}>
                    {userDetails?.message?.includes("Bye") &&
                      "Workout Minutes : " +
                        (
                          (Number(userDetails?.timeOut) -
                            Number(userDetails?.timeIn)) /
                          60000
                        ).toFixed(0)}
                  </p>
                </strong>
                Valid Till: {userDetails?.expiresOn?.split("T")[0]}
                <br />
                Expires in{" "}
                {Math.ceil(
                  (new Date(userDetails?.expiresOn) - new Date()) /
                    (1000 * 60 * 60 * 24)
                ) === 0
                  ? "Expiring Today"
                  : Math.ceil(
                      (new Date(userDetails?.expiresOn) - new Date()) /
                        (1000 * 60 * 60 * 24)
                    ) + " days"}{" "}
                <br />
                {userDetails?.pendingAmount > 0 && (
                  <label style={{ color: "red" }}>
                    Pending Amount : {userDetails?.pendingAmount}
                  </label>
                )}
                <br />
                <br />
                <strong>{new Date().toDateString()}</strong>
                <br />
                <strong>
                  {new Date().toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </strong>
                <br />
                <br />
                want to enter Member Id ? click the below button and scan again
                <br />
                <Button
                  style={{ fontSize: "1.5rem", backgroundColor: "green" }}
                  onClick={clearStorage}
                >
                  Scan Again
                </Button>
              </>
            )}
          </Col>
        </Row>
      ) : (
        !localStorage.getItem("userId") && (
          <FormContainer>
            <Form onSubmit={submitHandler}>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Form.Group className="my-2" controlId="userId">
                  <Form.Label id="userId">
                    Enter Your ID to mark In or Out time
                  </Form.Label>
                  <Form.Control
                    style={{ height: "50px" }}
                    type="text"
                    placeholder="enter Id"
                    autoComplete="off"
                    autoFocus
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                  />
                </Form.Group>
                <br />
                <Button
                  style={{ height: "40px" }}
                  className="cyanThemeHeaderTop"
                  type="submit"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
              {isLoading && <Loader />}
            </Form>
          </FormContainer>
        )
      )}
      {userIdError && localStorage.getItem("userId") && (
        <div
          style={{
            fontSize: "1.5rem",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              fontSize: "1.5rem",
              color: "yellow",
            }}
          >
            Attendance Not Marked.
            <br />
            <br /> click the below button, then scan again and enter your Member
            Id
            <br />
            <br />
          </label>
          <Button
            style={{ fontSize: "2rem", backgroundColor: "green" }}
            onClick={clearStorage}
          >
            Scan Again
          </Button>
        </div>
      )}
    </div>
  );
};

export default AttendanceScreenFromMobile;
