import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useGetAttendanceQuery } from "../slices/attendanceSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getCurrentDate, getDateOfLast } from "../utils/getCurrentDate";
import { setCredentials } from "../slices/authSlice";
import preferredLanguage from "../assets/preferredLanguage.json";

const AttendanceListScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const offset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;

  const [startDate, setStartDate] = useState(getCurrentDate());

  const [endDate, setEndDate] = useState(getCurrentDate());

  let startDate1 =
    new Date(`${getCurrentDate()}T00:00:00.567Z`).getTime() - offset;

  let endDate1 =
    new Date(`${getCurrentDate()}T23:59:59.567Z`).getTime() - offset;

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&timeIn[gte]=${startDate1}&timeIn[lte]=${endDate1}&fields=userId,firstName,timeIn,timeOut,attendanceDate&sort=-timeIn`
  );

  const {
    data: attendances,
    isLoading,
    error,
    refetch,
  } = useGetAttendanceQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const [usersToList, setUsersToList] = useState(
    isLoading ? [] : attendances ? attendances : []
  );

  const [todaysUsers, setTodaysUsers] = useState(
    isLoading ? [] : attendances ? attendances.length : 0
  );
  const [maleUsers, setMaleUsers] = useState("");
  const [femaleUsers, setfemaleUsers] = useState("");
  const [seniorCitizen, setSeniorCitizen] = useState("");
  const [ptEnabledUsers, setPtEnabledUsers] = useState("");
  const [currentUsersAtGym, setCurrentUsersAtGym] = useState(0);

  const [todaysUniqueUsers, setTodaysUniqueUsers] = useState(
    isLoading ? [] : attendances ? attendances.length : 0
  );
  const [maleUniqueUsers, setMaleUniqueUsers] = useState("");
  const [femaleUniqueUsers, setfemaleUniqueUsers] = useState("");
  const [seniorUniqueCitizen, setSeniorUniqueCitizen] = useState("");
  const [ptEnabledUniqueUsers, setPtEnableUniqueUsers] = useState("");

  const dispatch = useDispatch();

  const handleGetUsers = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      startDate1 = new Date(`${startDate}T00:00:00.567Z`).getTime() - offset;
      endDate1 = new Date(`${endDate}T23:59:59.567Z`).getTime() - offset;

      setGetApiQuery(
        `?gymId=${userInfo.gymId._id}&timeIn[gte]=${startDate1}&timeIn[lte]=${endDate1}&fields=userId,firstName,timeIn,timeOut,attendanceDate&sort=-timeIn`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          attendancesStartDate: startDate,
          attendancesEndDate: endDate,
        })
      );
    }
  };

  const [initApiRequestAtIntervals, setInitApiRequestAtIntervals] = useState(1);
  setInterval(() => {
    setInitApiRequestAtIntervals(Date.now());
  }, 1200000);

  useEffect(() => {
    refetch();
  }, [refetch, initApiRequestAtIntervals]);

  const getUniqueUsers = (users) => {
    let uniqueUsersIds = [];
    let uniqueUsers = [];
    users.map((user) => {
      if (!uniqueUsersIds.includes(user.userId)) {
        uniqueUsersIds.push(user.userId);
        uniqueUsers.push(user);
      }
      return null;
    });
    return uniqueUsers;
  };

  useEffect(() => {
    if (error?.data?.error) {
      setTodaysUsers("");
      setMaleUsers("");
      setfemaleUsers("");
      setSeniorCitizen("");
      setPtEnabledUsers("");
      setCurrentUsersAtGym("");
      setTodaysUniqueUsers("");
      setMaleUniqueUsers("");
      setfemaleUniqueUsers("");
      setSeniorUniqueCitizen("");
      setPtEnableUniqueUsers("");
    }
  }, [error]);

  useEffect(() => {
    const todaysUsers = isLoading ? [] : attendances ? attendances : [];
    setTodaysUsers(todaysUsers);
    setUsersToList(todaysUsers);
    setTodaysUniqueUsers(getUniqueUsers(todaysUsers));
    const currentUsers = isLoading
      ? []
      : attendances
      ? attendances.filter((att) => {
          return att.timeIn >= Date.now() - 3600000 && !att.timeOut;
        })
      : [];
    setCurrentUsersAtGym(currentUsers);
    const maleUsers = isLoading
      ? []
      : attendances
      ? attendances.filter(
          (att) => att.fullUserId?.gender?.toString().toLowerCase() === "male"
        )
      : [];
    setMaleUsers(maleUsers);
    setMaleUniqueUsers(getUniqueUsers(maleUsers));
    const femaleUsers = isLoading
      ? []
      : attendances
      ? attendances.filter(
          (att) => att.fullUserId?.gender?.toString().toLowerCase() === "female"
        )
      : [];
    setfemaleUsers(femaleUsers);
    setfemaleUniqueUsers(getUniqueUsers(femaleUsers));
    const seniorUsers = isLoading
      ? []
      : attendances
      ? attendances.filter(
          (att) =>
            50 <
            Math.floor(
              (new Date() - new Date(att.fullUserId?.dateOfBirth)) /
                (365.25 * 24 * 60 * 60 * 1000)
            )
        )
      : [];
    setSeniorCitizen(seniorUsers);
    setSeniorUniqueCitizen(getUniqueUsers(seniorUsers));
    const ptEnabledUsers = isLoading
      ? []
      : attendances
      ? attendances.filter((att) => att.fullUserId?.ptEnabled)
      : [];
    setPtEnabledUsers(ptEnabledUsers);
    setPtEnableUniqueUsers(getUniqueUsers(ptEnabledUsers));
  }, [setTodaysUsers, attendances, isLoading]);

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };
  const handleUniqueUsersSelection = (val) => {
    setUsersToList(val);
  };

  const themeColor = "color4";

  return (
    <>
      <label>
        <strong>
          {
            preferredLanguage[selectedLanguage]?.attendanceListPage
              .attendanceList
          }
        </strong>
      </label>
      <Row
        className="mb-1"
        style={{
          justifyContent: "center",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-27"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group className="my-1" controlId="gymType">
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              onClick={handleGetUsers}
            >
              Get Data
            </Button>
          </Form.Group>
        </Col>
      </Row>
      {userInfo.role === "owner" ||
      userInfo.role === "Super Admin" ||
      userInfo.role === "admin" ? (
        <>
          <Row
            className="mb-1"
            style={{
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Button
              className="marginPointEight sixteenWidth buttonGreen numbersButton"
              onClick={() => handleFilterSelecttion(currentUsersAtGym)}
            >
              At Gym <strong> {currentUsersAtGym?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonPeach numbersButton"
              onClick={() => handleFilterSelecttion(todaysUsers)}
            >
              Total <strong> {todaysUsers?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonTeal numbersButton"
              onClick={() => handleFilterSelecttion(maleUsers)}
            >
              Male <strong> {maleUsers?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonMaroon numbersButton"
              onClick={() => handleFilterSelecttion(femaleUsers)}
            >
              Female <strong> {femaleUsers?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonOlive numbersButton"
              onClick={() => handleFilterSelecttion(seniorCitizen)}
            >
              Seniors <strong> {seniorCitizen?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonLoveBlue numbersButton"
              onClick={() => handleFilterSelecttion(ptEnabledUsers)}
            >
              PT-User <strong> {ptEnabledUsers?.length}</strong>
            </Button>
          </Row>
          <Row
            className="dataRow"
            style={{
              justifyContent: "center",
              marginBottom: "2%",
            }}
          >
            <Button className="marginPointOne sixteenWidth buttonGreen numbersButton">
              Unique
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonTeal numbersButton"
              onClick={() => handleUniqueUsersSelection(todaysUniqueUsers)}
            >
              {preferredLanguage[selectedLanguage]?.attendanceListPage.total}{" "}
              <strong>{todaysUniqueUsers?.length} </strong>
            </Button>
            {/* <Button
          className="marginPointOne sixteenWidth buttonPeach numbersButton"
          onClick={() => handleUniqueUsersSelection(todaysUniqueUsers)}
        >
          Total <strong> {todaysUniqueUsers.length}</strong>
        </Button> */}
            <Button
              className="marginPointOne sixteenWidth buttonReddish numbersButton"
              onClick={() => handleUniqueUsersSelection(maleUniqueUsers)}
            >
              {preferredLanguage[selectedLanguage]?.attendanceListPage.male}{" "}
              <strong> {maleUniqueUsers?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonGreenish numbersButton"
              onClick={() => handleUniqueUsersSelection(femaleUniqueUsers)}
            >
              {preferredLanguage[selectedLanguage]?.attendanceListPage.female}{" "}
              <strong> {femaleUniqueUsers?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonOrchid numbersButton"
              onClick={() => handleUniqueUsersSelection(seniorUniqueCitizen)}
            >
              {preferredLanguage[selectedLanguage]?.attendanceListPage.seniors}{" "}
              <strong> {seniorUniqueCitizen?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonOrange numbersButton"
              onClick={() => handleUniqueUsersSelection(ptEnabledUniqueUsers)}
            >
              {preferredLanguage[selectedLanguage]?.attendanceListPage.ptUser}{" "}
              <strong> {ptEnabledUniqueUsers?.length}</strong>
            </Button>
          </Row>
        </>
      ) : (
        ""
      )}

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : usersToList ? (
        <div style={{ height: "100vh" }}>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr>
                  <th>Name</th>
                  <th>Id</th>
                  <th>Date</th>
                  <th>In</th>
                  <th>Out</th>
                  <th>Pending</th>
                  <th>Valid Till</th>
                  <th>Days Left</th>
                </tr>
              </thead>
              <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                {usersToList.map((user) => (
                  <tr>
                    <td>
                      <Link
                        style={{
                          color:
                            new Date(user.fullUserId?.subscriptionExpiryDate) <
                            new Date(getCurrentDate())
                              ? "red"
                              : user.fullUserId?.subscriptionExpiryDate
                                  .split("T")[0]
                                  .toString() === getCurrentDate()
                              ? "#fc9834"
                              : "inherit",
                        }}
                        to={`/attendancesOfUser/${user.userId}`}
                      >
                        {user.firstName}{" "}
                      </Link>
                    </td>
                    <td
                      style={{
                        color:
                          new Date(user.fullUserId?.subscriptionExpiryDate) <
                          new Date(getCurrentDate())
                            ? "red"
                            : user.fullUserId?.subscriptionExpiryDate
                                .split("T")[0]
                                .toString() === getCurrentDate()
                            ? "#fc9834"
                            : "inherit",
                      }}
                    >
                      {user.userId}
                    </td>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        color:
                          new Date(user.fullUserId?.subscriptionExpiryDate) <
                          new Date(getCurrentDate())
                            ? "red"
                            : user.fullUserId?.subscriptionExpiryDate
                                .split("T")[0]
                                .toString() === getCurrentDate()
                            ? "#fc9834"
                            : "inherit",
                      }}
                    >
                      {new Date(user.timeIn).getFullYear() +
                        "-" +
                        (new Date(user.timeIn).getMonth() + 1)
                          .toString()
                          .padStart(2, "0") +
                        "-" +
                        new Date(user.timeIn)
                          .getDate()
                          .toString()
                          .padStart(2, "0")}
                    </td>
                    <td
                      style={{
                        color:
                          new Date(user.fullUserId?.subscriptionExpiryDate) <
                          new Date(getCurrentDate())
                            ? "red"
                            : user.fullUserId?.subscriptionExpiryDate
                                .split("T")[0]
                                .toString() === getCurrentDate()
                            ? "#fc9834"
                            : "inherit",
                      }}
                    >
                      {" "}
                      {new Date(user.timeIn).getHours() +
                        ":" +
                        (new Date(user.timeIn).getMinutes() < 10
                          ? "0" + new Date(user.timeIn).getMinutes()
                          : new Date(user.timeIn).getMinutes())}
                    </td>
                    <td
                      style={{
                        color:
                          new Date(user.fullUserId?.subscriptionExpiryDate) <
                          new Date(getCurrentDate())
                            ? "red"
                            : user.fullUserId?.subscriptionExpiryDate
                                .split("T")[0]
                                .toString() === getCurrentDate()
                            ? "#fc9834"
                            : "inherit",
                      }}
                    >
                      {" "}
                      {user.timeOut
                        ? new Date(user.timeOut).getHours() +
                          ":" +
                          (new Date(user.timeOut).getMinutes() < 10
                            ? "0" + new Date(user.timeOut).getMinutes()
                            : new Date(user.timeOut).getMinutes())
                        : "No"}
                    </td>
                    <td
                      style={{
                        color:
                          new Date(user.fullUserId?.subscriptionExpiryDate) <
                          new Date(getCurrentDate())
                            ? "red"
                            : user.fullUserId?.subscriptionExpiryDate
                                .split("T")[0]
                                .toString() === getCurrentDate()
                            ? "#fc9834"
                            : "inherit",
                      }}
                    >
                      <label
                        style={{
                          color:
                            user.fullUserId?.pendingAmount > 0
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {user.fullUserId?.pendingAmount}
                      </label>
                    </td>
                    <td
                      style={{
                        color:
                          new Date(user.fullUserId?.subscriptionExpiryDate) <
                          new Date(getCurrentDate())
                            ? "red"
                            : user.fullUserId?.subscriptionExpiryDate
                                .split("T")[0]
                                .toString() === getCurrentDate()
                            ? "#fc9834"
                            : "inherit",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {
                        user?.fullUserId?.subscriptionExpiryDate
                          .toString()
                          .split("T")[0]
                      }
                    </td>
                    <td
                      style={{
                        color:
                          new Date(user.fullUserId?.subscriptionExpiryDate) <
                          new Date(getCurrentDate())
                            ? "red"
                            : user.fullUserId?.subscriptionExpiryDate
                                .split("T")[0]
                                .toString() === getCurrentDate()
                            ? "#fc9834"
                            : "inherit",
                      }}
                    >
                      {Math.ceil(
                        (new Date(user?.fullUserId?.subscriptionExpiryDate) -
                          new Date()) /
                          3600000 /
                          24
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AttendanceListScreen;
