import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  const footerYear = new Date().getFullYear();
  return (
    <>
      <footer>
        <Container>
          <Row>
            <Col className="text-center py-3">
              <label style={{ fontSize: "0.8rem", color: "black" }}>
                {" "}
                Version 1.0.0
              </label>
              <br />
              <br />
              <p>zpotter technologies &copy; {footerYear}</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
