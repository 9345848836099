import React from "react";
import { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useCreateVisitorMutation } from "../slices/visitorsSlice";
import { getCurrentDate } from "../utils/getCurrentDate";
import FieldWarning from "../components/FieldWarning";

const VisitorInputScreen = () => {
  // const { userInfo } = useSelector((state) => state.auth);
  const { gymUid: gymIdInUrl } = useParams();
  let gymIdInUrlB = gymIdInUrl.replaceAll("gymname");
  const navigate = useNavigate();
  const [visitorName, setVisitorName] = useState("");
  const [visitorMobile, setVisitorMobile] = useState("");
  const [gymName, setGymName] = useState(gymIdInUrl?.split("gymname")[1]);
  const redirectUrl = false;
  // alert(`${startVal}${endVal}`);
  let encodeVal = Date.now().toString();
  const startVal = encodeVal?.substring(0, 5);
  const endVal = encodeVal?.substring(5);
  // console.log("gymIdInUrl");
  // console.log(gymIdInUrl);
  useEffect(() => {
    // alert(`${startVal}${gymIdInUrl}${endVal}`);
    setGymName(gymIdInUrl?.split("gymname")[1]);
    navigate(
      `/visitorInputScreen/${startVal}${gymIdInUrl?.split("gymname")[0]
      }${endVal}`
    );
  }, [redirectUrl, navigate]);

  const [createVisitor, isLoading] = useCreateVisitorMutation();

  const verifyInputFeilds = async () => {
    let fieldVerificationSuccess = true;

    if (visitorName?.length < 2 || visitorName?.length > 100) {
      setVisitorNamePresent(true);
      fieldVerificationSuccess = false;
    }

    if (
      visitorMobile?.length !== 10 ||
      Number(visitorMobile) <= 6000000000 ||
      Number(visitorMobile) >= 9999999999
    ) {
      setVisitorMobilePresent(true);
      fieldVerificationSuccess = false;
    }

    return fieldVerificationSuccess;
  };

  const handleSubmit = async () => {
    if (await verifyInputFeilds())
      try {
        await createVisitor({
          payload: {
            name: visitorName,
            mobileNumber: visitorMobile,
            visitedDate: getCurrentDate(),
          },
          params: gymIdInUrlB,
          // token: userInfo?.token,
        }).unwrap();
        // toast.success("Thank You");
        setVisitorName("");
        setVisitorMobile("");
        navigate("/detailsToVisitorsScreen/" + gymIdInUrlB);
      } catch (e) {
        toast.error(e?.data?.error || e.data);
      }
  };

  const [visitorNamePresent, setVisitorNamePresent] = useState("");
  const [visitorMobilePresent, setVisitorMobilePresent] = useState("");
  const [newUserAdded, setNewUserAdded] = useState("");

  useEffect(() => {
    if (visitorName?.length > 0) {
      setVisitorNamePresent(false);
    }
  }, [visitorName]);

  useEffect(() => {
    if (visitorMobile?.length > 1 && Number(visitorMobile) > 0) {
      setVisitorMobilePresent(false);
    }
  }, [visitorMobile]);

  return (
    <>
      <h1> {gymName?.replaceAll("_", " ")}</h1>
      <h6> Enter Your Details to know the Details of this Gym</h6>
      <Row
        style={{
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
          width: "85%",
        }}
      >
        <Form.Group as={Row} className="my-2" controlId="firstName">
          <Form.Label column="true" sm="3">
            Your Name :
          </Form.Label>
          <Col xs={12} md={4} sm={12}>
            <Form.Control
              type="text"
              value={visitorName}
              onChange={(e) => setVisitorName(e.target.value)}
              placeholder=" Enter Name"
            />
            {visitorNamePresent ? (
              <FieldWarning text="Please enter firstname" />
            ) : (
              " "
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="my-2" controlId="visitorMobile">
          <Form.Label column="true" sm="3">
            Your Mobile Number:
          </Form.Label>
          <Col xs={12} md={4} sm={12}>
            <Form.Control
              type="text"
              pattern="[0-9]*"
              value={visitorMobile}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                setVisitorMobile(value);
              }}
              placeholder=" Enter Mobile number"
            />
            {visitorMobilePresent ? (
              <FieldWarning text="Please enter valid number" />
            ) : (
              " "
            )}
          </Col>
        </Form.Group>
        <Button
          onClick={handleSubmit}
          style={{ width: "6rem", margin: "2rem", marginTop: "1rem" }}
        >
          Submit
        </Button>
      </Row>
    </>
  );
};

export default VisitorInputScreen;
