import { apiSlice } from "./apiSlice";
import { USER_URL } from "../constants";

const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (body) => ({
        url: `${USER_URL}${body.query ? body.query : ""}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getUser: builder.query({
      query: (body) => ({
        url: `${USER_URL}/${body.payLoad}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    createUsers: builder.mutation({
      query: (data) => ({
        url: `${USER_URL}`,
        method: "POST",
        body: data.payLoad,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `${USER_URL}/${data.userId}`,
        method: "PUT",
        body: data.payLoad,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    uploadUsers: builder.mutation({
      query: (data) => ({
        url: `${USER_URL}/upload`,
        method: "POST",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    uploadPhoto: builder.mutation({
      query: (data) => ({
        url: `${USER_URL}/uploadPhoto`,
        method: "POST",
        body: data.payload,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    getUsersDashobaord: builder.query({
      query: (body) => ({
        url: `${USER_URL}/dashboard${body.query ? body.query : ""}`,
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    deleteUserData: builder.mutation({
      query: (data) => ({
        url: `${USER_URL}/${data.userId}`,
        method: "DELETE",
        body: data,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
    updateSubscription: builder.mutation({
      query: (data) => ({
        url: `${USER_URL}/${data.userId}/updateSubscription`,
        method: "PUT",
        body: data.payLoad,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUsersMutation,
  useUpdateUserMutation,
  useUploadUsersMutation,
  useGetUsersDashobaordQuery,
  useUploadPhotoMutation,
  useDeleteUserDataMutation,
  useUpdateSubscriptionMutation,
} = usersApiSlice;
