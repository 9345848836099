import React from "react";
import { createRoot } from "react-dom/client";
//import './index.css';
//import 'bootstrap/dist/css/bootstrap.min.css'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/index.css";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import PrivateRoute from "./components/PrivateRoute";
import AttendanceScreen from "./screens/AttendanceScreen";
import UsersScreen from "./screens/UsersScreen";
import MyProfileScreen from "./screens/MyProfileScreen";
import UserProfileScreen from "./screens/UserProfileScreen";
import AttendanceListScreen from "./screens/AttendanceListScreen";
import UserRegistrationScreen from "./screens/UserRegistrationScreen";
import DashBoard from "./screens/DashBoard";
import VisitorsScreen from "./screens/VisitorsScreen";
import AttendanceScreenFromMobile from "./screens/AttendanceScreenFromMobile";
import QrCodeScreen from "./screens/QrCodeScreen";
import InActiveUsersScreen from "./screens/InActiveUsersScreen";
import PaymentListScreen from "./screens/PaymentListScreen";
import AssociateRegisterScreen from "./screens/AssociateRegisterScreen";
import AssociatesScreen from "./screens/AssociatesScreen";
import AssociateProfileScreen from "./screens/AssociateProfileScreen";
import ChangePasswordScreen from "./screens/ChangePasswordScreen";
import SubsExpiredUsersScreen from "./screens/SubsExpiredUsersScreen";
import UsersUploadScreen from "./screens/UsersUploadScreen";
import UserSelfRegistrationScreen from "./screens/UserSelfRegistrationScreen";
import QrCodeForRegScreen from "./screens/QrCodeForRegScreen";
import PendingRegsScreen from "./screens/PendingRegsScreen";
import UserSelfProfileScreen from "./screens/UserSelfProfileScreen";
import AccountsScreen from "./screens/AccountsScreen";
import UserPaymentsScreen from "./screens/UserPaymentsScreen";
import AttendancesOfUserScreen from "./screens/AttendancesOfUserScreen";
import NumberForUsersScreen from "./screens/NumberForUsersScreen";
// import QRScanner from "./screens/QRScanner";
import NumberForUsersAppScreen from "./screens/NumberForUsersAppScreen";
import GymProfileScreen from "./screens/GymProfileScreen";
import GymPaymentUpdateScreen from "./screens/GymPaymentUpdateScreen";
import CustomerDetailsScreen from "./screens/CustomerDetailsScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import WsMessageListScreen from "./screens/WsMessageListScreen";
import WhatsAppAdsScreen from "./screens/WhatsAppAdsScreen";
import WsMessageAdsListScreen from "./screens/WsMessageAdsListScreen";
import EquipmentsScreen from "./screens/EquipmentsScreen";
import TrendsScreen from "./screens/TrendsScreen";
import AssociateAttendanceScreen from "./screens/AssociateAttendanceScreen";
import AssociateAttendanceFromMobile from "./screens/AssociateAttendanceFromMobile";
import EditAttendancePageScreen from "./screens/EditAttendancePageScreen";
import AllUsersScreen from "./screens/AllUsersScreen";
import DownloadAppScreen from "./screens/DownloadAppScreen";
import PackagesScreen from "./screens/PackagesScreen";
import SubscriptionsScreen from "./screens/SubscriptionsScreen";
import ShowToVisitorsScreen from "./screens/ShowToVisitorsScreen";
import VisitorInputScreen from "./screens/VisitorInputScreen";
import DetailsToVisitorsScreen from "./screens/DetailsToVisitorsScreen";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/privacyPolicy" element={<PrivacyPolicyScreen />} />
      <Route path="/resetPassword" element={<ForgotPasswordScreen />} />
      {/* <Route path="/qrscanner" element={<QRScanner />} /> */}
      <Route path="/usersAtGym/:id" element={<NumberForUsersScreen />} />
      <Route path="/usersAtGymApp" element={<NumberForUsersAppScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
      <Route
        path="/attendanceFromMobile/:gymUid"
        element={<AttendanceScreenFromMobile />}
      />{" "}
      <Route
        path="/visitorInputScreen/:gymUid"
        element={<VisitorInputScreen />}
      />
      <Route
        path="/detailsToVisitorsScreen/:gymUid"
        element={<DetailsToVisitorsScreen />}
      />
      <Route path="/markAttendance" element={<AttendanceScreen />} />{" "}
      <Route
        path="/associateAttendanceFromMobile/:gymUid"
        element={<AssociateAttendanceFromMobile />}
      />
      <Route
        path="/userSelfRegistration/:gymUid"
        element={<UserSelfRegistrationScreen />}
      />
      <Route path="" element={<PrivateRoute />}>
        <Route
          path="/updateAttendancePageScreen"
          element={<EditAttendancePageScreen />}
        ></Route>
        <Route path="/users/:id" element={<UserProfileScreen />}></Route>
        <Route path="/users" element={<UsersScreen />}></Route>
        <Route path="/allUsers" element={<AllUsersScreen />}></Route>
        <Route path="/downloadApps" element={<DownloadAppScreen />}></Route>
        <Route path="/regPendingUsers" element={<PendingRegsScreen />}></Route>
        <Route
          path="/usersSelfProfile/:id"
          element={<UserSelfProfileScreen />}
        ></Route>
        <Route path="/subsExpiredUsers" element={<SubsExpiredUsersScreen />} />
        <Route path="/subscriptions" element={<SubscriptionsScreen />} />
        <Route path="/trends" element={<TrendsScreen />} />
        <Route path="/packages" element={<PackagesScreen />} />
        <Route path="/userPayments/:id" element={<UserPaymentsScreen />} />
        <Route path="/" element={<AttendanceListScreen />}></Route>
        <Route path="/home" element={<HomeScreen />}></Route>
        <Route path="/profile" element={<MyProfileScreen />}></Route>
        <Route path="/gymProfile" element={<GymProfileScreen />}></Route>
        <Route
          path="/customersDetails"
          element={<CustomerDetailsScreen />}
        ></Route>
        <Route
          path="/gymPaymentUpdate"
          element={<GymPaymentUpdateScreen />}
        ></Route>
        <Route path="/dashboard" element={<DashBoard />}></Route>
        <Route path="/equipments" element={<EquipmentsScreen />}></Route>
        <Route
          path="/wsMessageAdsList"
          element={<WsMessageAdsListScreen />}
        ></Route>
        <Route path="/accounts" element={<AccountsScreen />}></Route>
        <Route path="/visitors" element={<VisitorsScreen />}></Route>
        <Route path="/qrCode" element={<QrCodeScreen />} />
        <Route path="/contactUs" element={<ContactUsScreen />} />
        <Route path="/inactiveusers" element={<InActiveUsersScreen />} />
        <Route path="/payments" element={<PaymentListScreen />} />
        <Route
          path="/associateRegister"
          element={<AssociateRegisterScreen />}
        />
        <Route path="/associates" element={<AssociatesScreen />} />
        <Route
          path="/associatesAttendance"
          element={<AssociateAttendanceScreen />}
        />
        <Route path="/associate/:id" element={<AssociateProfileScreen />} />
        <Route
          path="/userRegistration"
          element={<UserRegistrationScreen />}
        ></Route>
        <Route path="/qrcodeRegister" element={<QrCodeForRegScreen />} />
        <Route path="/showToVisitors" element={<ShowToVisitorsScreen />} />
        <Route path="/promotionalMessage" element={<WhatsAppAdsScreen />} />
        <Route path="/uploadUsers" element={<UsersUploadScreen />} />
        <Route path="/changePassword" element={<ChangePasswordScreen />} />
        <Route
          path="/attendanceList"
          element={<AttendanceListScreen />}
        ></Route>
        <Route
          path="/attendancesOfUser/:id"
          element={<AttendancesOfUserScreen />}
        ></Route>
        <Route
          path="/whatsAppMessages"
          element={<WsMessageListScreen />}
        ></Route>
      </Route>
    </Route>
  )
);

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
