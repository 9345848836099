import { React, useEffect, useState } from "react";
import { Button, Table, Row, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";

import Message from "../components/Message";
import { toast } from "react-toastify";
import { useGetAssociatesQuery } from "../slices/associatesSlice";
import preferredLanguage from "../assets/preferredLanguage.json";
import AssociateRegisterScreen from "./AssociateRegisterScreen";
import { useGetMyGymQuery, useUpdateMyGymMutation } from "../slices/gymSlice";
import CreatePackageScreen from "./CreatePackageScreen";
import VerticallyCenteredModal from "../components/SubmitModal";

const PackagesScreen = () => {
  const [searchBy, setSearchBy] = useState("Id");
  const [searchValue, setSearchValue] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const [showAdd, setShowAdd] = useState(false);
  const [reload, setReload] = useState(false);
  const [deletePackage, setDeletePackage] = useState();

  const [modalShow, setModalShow] = useState(false);
  const [addAdmissionFeePressed, setAddAdmissionFeePressed] = useState(false);
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";

  const searchOptions = {
    Id: "_id",
    Name: "firstName",
    Mobile: "mobileNumber",
  };
  let {
    data: gymDetails,
    isLoading,
    refetch,
    error,
  } = useGetMyGymQuery({
    token: userInfo.token,
  });

  const [updatePackage, { isMutateLoading }] = useUpdateMyGymMutation();

  const [packagesToList, setPackagesToList] = useState(
    isLoading ? [] : gymDetails ? gymDetails?.customPackage : []
  );
  const [admissionFee, setAdmissionFee] = useState(
    isLoading ? 0 : gymDetails ? gymDetails?.admissionFee : 0
  );

  // console.log("packagesToList");
  // console.log(packagesToList);
  // console.log(gymDetails?.customPackage);

  useEffect(() => {
    refetch();
    setPackagesToList(
      isLoading ? [] : gymDetails ? gymDetails?.customPackage : []
    );
    setAdmissionFee(isLoading ? 0 : gymDetails ? gymDetails?.admissionFee : 0);
  }, [refetch, gymDetails, reload, addAdmissionFeePressed]);

  const handleBackButton = async () => {
    setShowAdd(!showAdd);
    setReload(!reload);
  };

  const handleDelete = async (pck) => {
    setModalShow(true);
    setDeletePackage(pck);
  };

  const submitHandler = async (e) => {
    try {
      let packDetails = packagesToList;
      const updatedPackages = Object.fromEntries(
        Object.entries(packDetails).filter(([key]) => key !== deletePackage)
      );
      setPackagesToList(updatedPackages);
      await updatePackage({
        payload: { customPackage: updatedPackages },
        token: userInfo.token,
      }).unwrap();
      toast.success("Package is deleted : " + deletePackage);
      // navigate("/");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const handleCancel = async () => {
    setAddAdmissionFeePressed(false);
  };

  const handleAddFee = async () => {
    try {
      const resData = await updatePackage({
        payload: { admissionFee },
        token: userInfo.token,
      }).unwrap();
      if (resData) {
        toast.success("Admission Fee added successfully");
        setAddAdmissionFeePressed(false);
      }
    } catch (e) {
      // console.log(e);
      toast.error("error in adding Admission Fee, Please try after some time");
    }
  };

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to Delete this ?"
        size="lg"
        payload={{
          "Package Name": deletePackage,
        }}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
      />
      <Modal
        show={addAdmissionFeePressed}
        onHide={(e) => setAddAdmissionFeePressed(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <strong>Enter Admission Fee or Registration Fee</strong>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label style={{ color: "black" }}>Amount :</Form.Label>
            <Form.Control
              type="text"
              value={admissionFee}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setAdmissionFee(value);
              }}
            ></Form.Control>
            <br />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            onClick={handleAddFee}
          >
            Submit
          </Button>

          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {showAdd ? (
        <>
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            style={{
              whiteSpace: "nowrap",
              margin: "1%",
            }}
            onClick={(e) => handleBackButton()}
          >
            Back
          </Button>
          <CreatePackageScreen existingPackages={packagesToList} />
        </>
      ) : (
        <>
          <h6>Package List</h6>
          <Row
            sm="4"
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: "1rem 0rem",
            }}
          >
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              style={{
                whiteSpace: "nowrap",
                margin: "1%",
                maxWidth: "8rem",
              }}
            >
              Packages
              <strong>
                {packagesToList && Object.keys(packagesToList)?.length}
              </strong>
            </Button>
            {userInfo.role === "owner" || userInfo.role === "Super Admin" ? (
              <>
                <Button
                  style={{
                    whiteSpace: "nowrap",
                    margin: "1%",
                    maxWidth: "8rem",
                  }}
                  className="marginPointOne buttonOrange"
                  onClick={(e) => setShowAdd(!showAdd)}
                  variant="primary"
                >
                  {preferredLanguage[selectedLanguage]?.createPackage}
                </Button>{" "}
                <Button
                  style={{
                    whiteSpace: "nowrap",
                    margin: "1%",
                    maxWidth: "10rem",
                  }}
                  className="buttonGreenish"
                  onClick={(e) =>
                    setAddAdmissionFeePressed(!addAdmissionFeePressed)
                  }
                  variant="primary"
                >
                  Admission Fee {admissionFee}
                </Button>
              </>
            ) : (
              <></>
            )}
          </Row>
          {isLoading ? (
            <Loader />
          ) : error ? (
            <Message>{error?.data?.error}</Message>
          ) : (
            <div style={{ height: "100vh" }}>
              <div className="tableContainer">
                <Table
                  striped
                  bordered
                  hover
                  responsive="sm"
                  className="table-custom"
                >
                  <thead className={`${userInfo.themeColor}ThemeThead`}>
                    <tr>
                      <th>Package Name</th>
                      <th>Original Price</th>
                      <th>Offer Price</th>
                      <th>Original Months</th>
                      <th>Offer Months</th>
                      <th>Days</th>
                      <th></th>
                    </tr>
                  </thead>
                  {packagesToList ? (
                    <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                      {Object.keys(packagesToList)?.map((packageName) => (
                        <tr key={packageName}>
                          <td>{packageName}</td>
                          <td> {packagesToList[packageName]?.price}</td>
                          <td> {packagesToList[packageName]?.offerPrice}</td>
                          <td>{packagesToList[packageName]?.originalMonths}</td>
                          <td> {packagesToList[packageName]?.months}</td>
                          <td> {packagesToList[packageName]?.extraDays}</td>
                          <td>
                            <Button
                              className={`${userInfo.themeColor}ThemeHeaderTop`}
                              onClick={(e) => handleDelete(packageName)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <Message>No Packages Available</Message>
                  )}
                </Table>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PackagesScreen;
