import React, { useState } from "react";
import { Card } from "react-bootstrap";
import defaultProfilePic from "../assets/defaultProfilePic.png";
import { useSelector } from "react-redux";

const ProfileImage = ({ imageId, setImageError }) => {
  const [altImage, setAltImage] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);

  const handleImageError = () => {
    if (setImageError) {
      setImageError(true);
    }
    setAltImage(true);
  };

  // const cacheBuster = new Date().getTime();

  return (
    <Card
      style={{
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        borderRadius: "10px",
        backgroundColor: "transparent",
      }}
    >
      {altImage ? (
        <img
          src={defaultProfilePic}
          width={userInfo?.isMobileScreen ? 200 : 350}
          height={userInfo?.isMobileScreen ? 200 : 350}
          alt="Profile"
          style={{ borderRadius: "10%" }}
        />
      ) : (
        <img
          src={`https://zpottergym.s3.ap-south-1.amazonaws.com/${imageId.substring(
            0,
            4
          )}/${imageId.substring(4)}`}
          width={userInfo?.isMobileScreen ? 200 : 350}
          height={userInfo?.isMobileScreen ? 200 : 350}
          onError={handleImageError} // Handle image loading error
          alt="Profile"
          style={{ borderRadius: "10%" }}
        />
      )}
    </Card>
  );
};

export default ProfileImage;
