import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGetShowToVisitorsDetailsQuery } from "../slices/gymSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";

const DetailsToVisitorsScreen = () => {
  const { gymUid: gymIdInUrl } = useParams();

  let {
    data: gymDetails,
    isLoading,
    error,
    refetch,
  } = useGetShowToVisitorsDetailsQuery({
    gymUid: gymIdInUrl,
  });

  const [detailsToShow, setDetailsToShow] = useState(gymDetails);

  useEffect(() => {
    refetch();
    setDetailsToShow(gymDetails);
  }, [refetch, gymDetails]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <>
          <h1>{detailsToShow?.name} </h1>
          <Row
            style={{
              alignItems: "center",
              marginTop: "1rem",
              justifyContent: " center",
              display: "flex",
            }}
          >
            <Col
              md="10"
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Form.Label
                style={{
                  fontSize: "1.1rem",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                  color: "white",
                  margin: "1rem",
                  whiteSpace: "pre-wrap",
                }}
              >
                {detailsToShow?.showToVisitors}
              </Form.Label>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default DetailsToVisitorsScreen;
