import { Button, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useUpdateUserMutation } from "../slices/usersSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import { getCurrentDate } from "../utils/getCurrentDate";
import FieldWarning from "../components/FieldWarning";

const UserEditScreen = ({ user, editStatus }) => {
  const [userId, setUserId] = useState(user.userId);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [gender, setGender] = useState(user.gender);
  const [mobileNumber, setmMbileNumber] = useState(user.mobileNumber);
  const [email, setEmail] = useState(user.email);
  const [aadharNumber, setAadharNumber] = useState(user.aadharNumber);
  const [heigthInCm, setHeigthInCm] = useState(user.heigthInCm);
  const [weightInKg, setWeightInKg] = useState(user.weightInKg);
  const [ptEnabled, setPtEnabled] = useState(user.ptEnabled);
  const [isActive, setIsActive] = useState(user.isActive);
  const [joinedDate, setJoinedDate] = useState(user.joinedDate?.split("T")[0]);
  const [dateOfBirth, setdDteOfBirth] = useState(
    user.dateOfBirth?.split("T")[0]
  );
  const [subscriptionType, setSubscriptionType] = useState(
    user.subscriptionType
  );
  const [gymGoal, setGymGoal] = useState(user.gymGoal);
  const [address, setAddress] = useState(user.address);
  const [modalShow, setModalShow] = useState(false);

  const [firstNamePresent, setFirstNamePresent] = useState(false);
  const [lastNamePresent, setLastNamePresent] = useState(false);
  const [genderPresent, setGenderPresent] = useState(false);
  const [mobileNumberPresent, setMobileNumberPresent] = useState(false);
  const [emailPresent, setEmailPresent] = useState("");
  const [aadharNumberPresent, setAadharNumberPresent] = useState(false);
  const [heigthInCmPresent, setHeigthInCmPresent] = useState(false);
  const [weightInKgPresent, setWeightInKgPresent] = useState(false);
  const [joinedDatePresent, setJoinedDatePresent] = useState(false);
  const [dateOfBirthPresent, setDateOfBirthPresent] = useState(false);
  const [subscriptionTypePresent, setSubscriptionTypePresent] = useState(false);
  const [gymGoalPresent, setGymGoalPresent] = useState(false);
  const [addressPresent, setAddressPresent] = useState(false);

  const genderList = ["Male", "Female", "Other"];
  const subscriptionList = [
    "Select Months",
    "1 Month",
    "3 Months",
    "6 Months",
    "12 Months",
  ];
  const gymGoalList = [
    "Select Gym Goal",
    "Weight Loss and Fat Reduction",
    "Muscle Gain and Strength Building",
    "Body Building",
    "Weight Lifting",
    "To Improve Stamina",
    "Stay Fit",
    "Health and Wellness",
    "Sports Performance",
    "Injury Rehabilitation",
  ];

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const [updateUser, { isLoading }] = useUpdateUserMutation();

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  useEffect(() => {
    if (firstName?.trim().length > 0) {
      setFirstNamePresent(false);
    }
    if (lastName?.trim().length > 0) {
      setLastNamePresent(false);
    }

    if (gender?.length > 1) {
      setGenderPresent(false);
    }

    if (mobileNumber?.length === 10 && mobileNumber > 1) {
      setMobileNumberPresent(false);
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email?.trim()) || email?.trim().length === 0) {
      setEmailPresent(false);
    }

    if (aadharNumber?.toString().length === 12 || aadharNumber?.length === 0) {
      setAadharNumberPresent(false);
    }

    if (
      (Number(heigthInCm) >= 80 && Number(heigthInCm) < 230) ||
      heigthInCm?.length === 0
    ) {
      setHeigthInCmPresent(false);
    }
    if (
      (Number(weightInKg) >= 20 && Number(weightInKg) < 200) ||
      weightInKg?.length === 0
    ) {
      setWeightInKgPresent(false);
    }

    if (
      subscriptionType !== undefined &&
      subscriptionType !== "Select Months"
    ) {
      setSubscriptionTypePresent(false);
    }

    if (gymGoal !== "Select Gym Goal") {
      setGymGoalPresent(false);
    }
    if (address?.trim().length > 10 || address?.trim().length === 0) {
      setAddressPresent(false);
    }

    if (dateOfBirth < getCurrentDate()) {
      setDateOfBirthPresent(false);
    }

    if (joinedDate <= getCurrentDate()) {
      setJoinedDatePresent(false);
    }
  }, [
    firstName,
    lastName,
    gender,
    mobileNumber,
    email,
    aadharNumber,
    heigthInCm,
    weightInKg,
    ptEnabled,
    dateOfBirth,

    joinedDate,

    subscriptionType,
    gymGoal,
    address,
  ]);

  const verifyInputFeilds = async () => {
    let fieldValidationSuccess = true;

    if (firstName?.trim().length < 1 || firstName?.trim().length > 100) {
      setFirstNamePresent(true);
      fieldValidationSuccess = false;
    }
    if (lastName?.trim().length > 100) {
      setLastNamePresent(true);
      fieldValidationSuccess = false;
    }

    if (gender?.length < 1) {
      setGenderPresent(true);
      fieldValidationSuccess = false;
    }

    if (mobileNumber?.toString().length !== 10 || mobileNumber < 1) {
      setMobileNumberPresent(true);
      fieldValidationSuccess = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email)
      if (!emailRegex.test(email?.trim()) || email?.trim().length > 300) {
        setEmailPresent(true);
        fieldValidationSuccess = false;
      }

    if (aadharNumber)
      if (aadharNumber?.toString().length !== 12 || Number(aadharNumber) < 1) {
        setAadharNumberPresent(true);
        fieldValidationSuccess = false;
      }

    if (Number(heigthInCm) > 0)
      if (Number(heigthInCm) < 80 || Number(heigthInCm) > 230) {
        setHeigthInCmPresent(true);
        fieldValidationSuccess = false;
      }
    if (Number(weightInKg) > 0)
      if (Number(weightInKg) < 20 || Number(weightInKg) > 200) {
        setWeightInKgPresent(true);
        fieldValidationSuccess = false;
      }

    if (subscriptionType === "Select Months" || !subscriptionType) {
      setSubscriptionTypePresent(true);
      fieldValidationSuccess = false;
    }

    if (gymGoal === "Select Gym Goal") {
      setGymGoalPresent(true);
      fieldValidationSuccess = false;
    }
    if (address)
      if (address?.trim().length < 10 || address?.trim().length > 3000) {
        setAddressPresent(true);
        fieldValidationSuccess = false;
      }

    if (dateOfBirth >= getCurrentDate() || !dateOfBirth) {
      setDateOfBirthPresent(true);
      fieldValidationSuccess = false;
    }

    if (joinedDate > getCurrentDate()) {
      setJoinedDatePresent(true);
      fieldValidationSuccess = false;
    }
    if (!fieldValidationSuccess)
      toast.error(
        "Some of the fields have invalid inputs. Please provide valid details"
      );
    return fieldValidationSuccess;
  };

  const submitHandler = async (e) => {
    // e.preventDefault();

    try {
      await updateUser({
        userId,
        payLoad: {
          firstName: firstName?.trim(),
          lastName: lastName?.trim(),
          gender,
          mobileNumber,
          email: email?.trim(),
          aadharNumber,
          heigthInCm,
          weightInKg,
          ptEnabled,
          isActive,
          dateOfBirth,
          subscriptionType,
          gymGoal,
          address: address?.trim(),
        },
        token: userInfo.token,
      }).unwrap();
      navigate(`/users/${user?.userId}`);
      editStatus();
      toast.success("User Details Updated Successfully");
    } catch (e) {
      toast.error(e?.data?.message || e.data);
    }
  };

  const handleCancelButton = () => {
    navigate(`/users/${user.userId}`);
    editStatus();
  };

  const handleSubmit = async (user) => {
    if (await verifyInputFeilds()) setModalShow(true);
  };

  return (
    <>
      <h5> Edit User Details</h5>
      <VerticallyCenteredModal
        title="Are you sure want to submit this ?"
        size="sm"
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={submitHandler}
        // setEdit={() => paymentStatus(true)}
      />

      <Row>
        <Col md={6}>
          <Form.Group as={Row} className="my-2" controlId="userId">
            <Form.Label column="true" sm="4">
              ID :
            </Form.Label>
            <Col sm="5">
              <Form.Label>{userId}</Form.Label>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="firtsName">
            <Form.Label column="true" sm="4">
              First Name * :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="enter First Name"
              ></Form.Control>
              {firstNamePresent ? (
                <FieldWarning text="Please provide first name" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="lastName">
            <Form.Label column="true" sm="4">
              Last Name :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="enter Last name"
              ></Form.Control>
              {lastNamePresent ? (
                <FieldWarning text="Last name can not have more than 100 characters" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="gender">
            <Form.Label column="true" sm="4">
              Select Gender * :
            </Form.Label>
            <Col sm="5">
              <div className="horizontal-radio-group">
                {genderList.map((option) => (
                  <Form.Check
                    key={option}
                    type="radio"
                    label={option}
                    name="genderRadio"
                    id={`genderRadio-${option}`}
                    value={option}
                    checked={gender === option}
                    onChange={(e) => setGender(e.target.value)}
                  />
                ))}
              </div>
              {genderPresent ? (
                <FieldWarning text="Please select gender" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="gymGoal">
            <Form.Label column="true" sm="4">
              Gym Goal * :
            </Form.Label>
            <Col sm="5">
              <Form.Select
                value={gymGoal}
                onChange={(e) => setGymGoal(e.target.value)}
                placeholder="Select Gym Goal"
              >
                {gymGoalList.map((e) => (
                  <option key={e} value={e}>
                    {e}
                  </option>
                ))}
              </Form.Select>{" "}
              {gymGoalPresent ? (
                <FieldWarning text="Please select gym goal" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          {/* <Form.Group as={Row} className="my-2" controlId="subscriptionType">
            <Form.Label column="true" sm="4">
              Subscription Type * :
            </Form.Label>
            <Col sm="5">
              <Form.Select
                value={subscriptionType}
                onChange={(e) => setSubscriptionType(e.target.value)}
                placeholder="Select subscription Type"
              >
                {subscriptionList.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </Form.Select>
              {subscriptionTypePresent ? (
                <FieldWarning text="Please select subscription type" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group> */}

          <Form.Group as={Row} className="my-2" controlId="ptEnabled">
            <Form.Label column="true" sm="4">
              Personal Trainer Availed ?{" "}
            </Form.Label>
            <Col sm="5">
              <div className="horizontal-radio-group">
                {[true, false].map((option) => (
                  <div key={option}>
                    <Form.Check
                      type="radio"
                      label={option === true ? "YES" : "NO"}
                      name="ptEnabled"
                      id={`ptEnabled-${option}`}
                      value={option}
                      checked={ptEnabled === option}
                      onChange={(e) => setPtEnabled(option)}
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="mobileNumber">
            <Form.Label column="true" sm="4">
              Mobile Number * :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={mobileNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setmMbileNumber(value);
                }}
                placeholder="enter mobile number"
              />
              {mobileNumberPresent ? (
                <FieldWarning text="Please provide 10 digit Mobile number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>

          {/* <Form.Group as={Row} className="my-2" controlId="joinedDate">
            <Form.Label column="true" sm="4">
              Joined Date * :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={joinedDate}
                onChange={(e) => setJoinedDate(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {joinedDatePresent ? (
                <FieldWarning text="Joined date can not be future date" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group> */}
        </Col>
        <Col md={6}>
          {/* <Form.Group as={Row} className="my-2" controlId="lastPaidAmount">
              <Form.Label column="true" sm="4">
                Paid Amount :
              </Form.Label>{" "}
              <Col sm="5">
                <Form.Control
                  type="text"
                  value={lastPaidAmount}
                  onChange={(e) => setLastPaidAmount(e.target.value)}
                  placeholder="enter recently Paid Amount"
                ></Form.Control>
              </Col>
            </Form.Group>{" "}
            <Form.Group as={Row} className="my-2" controlId="lastPaidDate">
              <Form.Label column="true" sm="4">
                Paid Date :
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  type="text"
                  value={lastPaidDate}
                  onChange={(e) => setLastPaidDate(e.target.value)}
                  placeholder="in format 'YYYY-MM-DD'"
                ></Form.Control>
              </Col>
            </Form.Group> */}
          <Form.Group as={Row} className="my-2" controlId="dateOfBirth">
            <Form.Label column="true" sm="4">
              Date Of Birth * :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="date"
                value={dateOfBirth}
                onChange={(e) => setdDteOfBirth(e.target.value)}
                placeholder="in format 'YYYY-MM-DD'"
              ></Form.Control>
              {dateOfBirthPresent ? (
                <FieldWarning text="Please provide valid date of birth" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="email">
            <Form.Label column="true" sm="4">
              Email :
            </Form.Label>
            <Col sm="5">
              {" "}
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="enter email"
              ></Form.Control>
              {emailPresent ? (
                <FieldWarning text="Please provide valid e-mail" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="aadharNumber">
            <Form.Label column="true" sm="4">
              Aadhar Number :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={aadharNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setAadharNumber(value);
                }}
                placeholder="enter aadhar number"
              />
              {aadharNumberPresent ? (
                <FieldWarning text="Please provide 12 digit Aadhar number" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="heigthInCm">
            <Form.Label column="true" sm="4">
              Heigth In Cm :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={heigthInCm}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setHeigthInCm(value);
                }}
                placeholder="enter heigth in centimeter"
              />
              {heigthInCmPresent ? (
                <FieldWarning text="Height should be between 80 cm and 230 cm" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="weightInKg">
            <Form.Label column="true" sm="4">
              Weight In Kg :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                pattern="[0-9]*" // Allow only digits
                value={weightInKg}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  setWeightInKg(value);
                }}
                placeholder="Enter recently Paid Amount"
              />
              {weightInKgPresent ? (
                <FieldWarning text="Weight should be between 20 kg and 200 kg" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="address">
            <Form.Label column="true" sm="4">
              Address :
            </Form.Label>
            <Col sm="5">
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="enter Address"
              ></Form.Control>
              {addressPresent ? (
                <FieldWarning text="Address should be between 10 and 3000 chars" />
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="my-2" controlId="isActive">
            <Form.Label column="true" sm="4">
              Active ?{" "}
            </Form.Label>
            <Col sm="5">
              <div className="horizontal-radio-group">
                {[true, false].map((option) => (
                  <div key={option}>
                    <Form.Check
                      type="radio"
                      label={option === true ? "YES" : "NO"}
                      name="isActive"
                      id={`isActive-${option}`}
                      value={isActive}
                      checked={isActive === option}
                      onChange={(e) => setIsActive(option)}
                    />
                  </div>
                ))}
              </div>
            </Col>
          </Form.Group>
        </Col>
        <Row
          sm="4"
          style={{
            alignItems: "center",
            justifyContent: "center",
            margin: "2rem 0rem",
          }}
        >
          <Button
            className={`${userInfo.themeColor}ThemeHeaderTop`}
            type="submit"
            disabled={isLoading}
            variant="primary"
            onClick={handleSubmit}
            style={{ maxWidth: "7rem" }}
          >
            {" "}
            Submit
          </Button>

          <Button
            style={{ marginLeft: "5%", maxWidth: "7rem" }}
            type="submit"
            disabled={isLoading}
            variant="secondary"
            onClick={handleCancelButton}
          >
            {" "}
            Cancel
          </Button>
        </Row>
      </Row>
    </>
  );
};

export default UserEditScreen;
