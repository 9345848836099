import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import QRCodeGenerator from "../utils/createQRcode";
import html2canvas from "html2canvas";
import { useGetMyGymQuery, useUpdateMyGymMutation } from "../slices/gymSlice";

const ShowToVisitorsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const gymName = userInfo?.gymId?.name?.replaceAll(" ", "_");
  const qrContent = `https://mygym.zpotter.in/visitorInputScreen/${userInfo.gymId._id}gymname${gymName}gymname`;

  let {
    data: myGymDetails,
    isMyGymDetailsLoading,
    error,
    refetch,
  } = useGetMyGymQuery({
    token: userInfo.token,
  });

  const [gymInfo, setGymInfo] = useState(
    myGymDetails ? myGymDetails?.showToVisitors : ""
  );

  useEffect(() => {
    refetch();
    setGymInfo(myGymDetails ? myGymDetails?.showToVisitors : "");
  }, [refetch, myGymDetails]);

  const [updateMyGym, isLoading] = useUpdateMyGymMutation();

  const submitHandler = async (e) => {
    try {
      await updateMyGym({
        payload: { showToVisitors: gymInfo },
        token: userInfo?.token,
      }).unwrap();

      toast.success("Show to Visitors details updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  const downloadPageAsImage = () => {
    const element = document.getElementById("qrPageContent");
    html2canvas(element, { scale: 2 }).then((canvas) => {
      const image = canvas.toDataURL("image/png"); // Convert to image
      const link = document.createElement("a");
      link.href = image;
      link.download = "QrPage.png"; // Name of the downloaded file
      link.click();
    });
  };

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          height: "80vh",
          marginTop: "5px",
          justifyContent: "center",
        }}
      >
        <h4>Show to Visitors</h4>
        <Col
          md="8"
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Control
            as="textarea"
            rows={12}
            value={gymInfo}
            onChange={(e) => setGymInfo(e.target.value)}
            placeholder="type here"
            style={{
              fontSize: "2rem",
              backgroundColor: "white",
              color: "black",
              alignContent: "center",
              marginTop: "5px",
              whiteSpace: "pre-wrap",
            }}
          ></Form.Control>
          <Button
            onClick={submitHandler}
            style={{ width: "6rem", margin: "auto", marginTop: "1rem" }}
          >
            Submit
          </Button>
        </Col>
      </Row>

      <Row style={{ marginTop: "1rem", justifyContent: "center" }}>
        <Col
          md={6}
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!userInfo?.isMobileScreen && (
            <Button
              className="marginPointOne sixteenWidth buttonTeal numbersButtonDashboard"
              onClick={downloadPageAsImage}
              style={{
                marginTop: "10px",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "5px",
                width: "40%",
              }}
            >
              Download QR Code
            </Button>
          )}
          <Row
            id="qrPageContent"
            className="mb-1"
            style={{
              alignItems: "center",
              margin: "auto",
              paddingBottom: "1%",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <h4 style={{ color: "black" }}>
              Scan This using Camera or Browser App to know about Gym Details
            </h4>
            <QRCodeGenerator
              content={qrContent}
              style={{
                width: "500px",
                height: "500px",
                justifyContent: "center",
              }}
            />
            <br />
            <br />
          </Row>
        </Col>
        <Col md={3}></Col>
      </Row>
    </>
  );
};

export default ShowToVisitorsScreen;
